import { isNotEmptyString } from 'common/utils/check-empty-string';
import dayjs from 'dayjs';
import { alphabetValidation } from 'validations/alphabet.validation';
import { emailValidation } from 'validations/email.validation';
import * as Yup from 'yup';

export const landlordProfileValidation = Yup.object({
  firstName: alphabetValidation.required('First Name is required'),
  lastName: alphabetValidation.required('Last Name is required'),
  email: emailValidation,
  dateOfBirth: Yup.date()
    .min(new Date(new Date().getFullYear() - 100, 0, 1), `Date can't be earlier than 100 years ago`)
    .nullable()
    .transform((curr, orig) => {
      return orig === '' && orig === 'Invalid Date' ? null : curr;
    })
    .typeError('Invalid date')
    .test({
      message: 'Date of birth can not be Less than 18 years from current date',
      test: (value) => dayjs(value).isSameOrBefore(dayjs().subtract(18, 'year'), 'day'),
    }),
  address: Yup.string(),
  city: alphabetValidation,
  state: Yup.string(),
  zip: Yup.string(),
  addressField: Yup.string().test(
    'can-be-submitted-address',
    'Please provide a valid address (Property Number/Street Name/City, State, Zip)',
    function (value) {
      if (isNotEmptyString(value || '')) {
        const address = this.resolve(Yup.ref('address')) as string;
        const city = this.resolve(Yup.ref('city')) as string;
        const state = this.resolve(Yup.ref('state')) as string;
        const zip = this.resolve(Yup.ref('zip')) as string;
        const nonEmptyStrings = [address, city, state, zip].filter(isNotEmptyString);

        if (nonEmptyStrings.length !== 4) {
          return false;
        }
      }
      return true;
    },
  ),
  jobTitle: Yup.string(),
  phoneNumber: Yup.string().required('Phone number is required'),
});

import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import {
  DetailsTour,
  HistoryTours,
  MyOffers,
  RenterProfileInformation,
  SavedSearch,
  UpcomingTours,
} from 'legacy-pages/pages';
import { Refferal } from 'components/templates/Refferal/Refferal';
import { TourHistoryTourDetailsPage } from 'components/templates/Landlord/TourHistoryPage/TourHistoryTourDetailsPage/TourHistoryTourDetailsPage';
import { AccountSecurity } from 'components/templates/Renter/AccountSecurity/AccountSecurity';

const RenterProfile = lazy(() => import('legacy-pages/renter/profile/renter-profile/renter-profile'));
const ChangePassword = lazy(() => import('legacy-pages/change-password/change-password'));
const ScheduleTour = lazy(() => import('legacy-pages/renter/schedule-tour/schedule-tour'));
const ScheduleTourCheckout = lazy(() => import('legacy-pages/renter/schedule-tour-checkout/schedule-tour-checkout'));
const PreOfferCheckout = lazy(() => import('legacy-pages/renter/pre-offer-checkout/pre-offer-checkout'));

export const renterRoutes = [
  { path: '', element: <Navigate to='/estates' replace /> },
  {
    path: 'profile',
    element: <RenterProfile />,
    children: [
      { path: '', element: <Navigate to={'info'} replace /> },
      { path: 'info/*', element: <RenterProfileInformation />, index: true },
      { path: 'tours/upcoming', element: <UpcomingTours /> },
      { path: 'tours/history', element: <HistoryTours /> },
      { path: 'saved-search', element: <SavedSearch /> },
      // { path: 'referral', element: <Refferal /> },
      { path: 'security', element: <AccountSecurity /> },
      { path: 'tours/:tourId', element: <DetailsTour /> },
      { path: 'offers', element: <MyOffers /> },
      { path: 'tours/history/:tourId', element: <TourHistoryTourDetailsPage /> },
    ],
  },
  { path: 'schedule-tour/:id', element: <ScheduleTour /> },
  { path: 'schedule-tour/checkout/:tourId', element: <ScheduleTourCheckout /> },
  { path: 'offers/checkout/:offerId', element: <PreOfferCheckout /> },
  { path: 'change-password', element: <ChangePassword /> },
];

import React from 'react';

export type FormDescriptionProps = {
  title: string;
  subTitle?: string;
};

const FormDescription = ({ title, subTitle }: FormDescriptionProps) => (
  <div className='flex flex-col gap-3'>
    <div className='title text-xl font-medium'>{title}</div>
    {subTitle && <div className='description text-trueGray'>{subTitle}</div>}
  </div>
);

export { FormDescription };

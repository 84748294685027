import CloseOutlined from '@mui/icons-material/CloseOutlined';
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

/** DialogTitle child component */
const DialogTitle = ({ text, component }) => {
    return (
        <div className="flex p-2"> 
            <h2 className="grow justify-start text-[20px]"><strong>{text}</strong></h2>
            <div className="justify-end">{component}</div>
        </div>
    );
}

/** SignsArea child component */
const FeeArea = ({ children }) => {
    return (
        <div className="grid grid-cols-1 text-[15px] mt-4">
            {children}
        </div>
    );
}

/** SignsArea child component */
const SignsArea = ({ children }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 text-[15px] mt-6">
            {children}
        </div>
    );
}

/** The main ConsentAcknowledgment component */
function ConsentAcknowledgment({ isOpen, onAcknowledge, onClose }) {
    
    const staticTexts = { 
        title: "Consent & Acknowledgment",
        applicationFee: "Application fee",
        applicantSignature: "Applicant's signature"
    };
   
    return (     
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle text={staticTexts.title} 
                component={
                    <Button className="w-[24px]" onClick={onClose}  >
                        <CloseOutlined color="primary" />
                    </Button>
                }
             />
            <DialogContent className="bg-sky-50 text-[14px] indent-2 rounded-md mx-2" sx={{padding: '10px 17px'}}>
                <p>I hereby certify that I am at least 18 years of age and that all information given on this application is 
                    true and correct.</p>
                <p>I authorize Axxelist/Landlord and its agents to obtain an investigative consumer 
                    credit report including, but not limited to, credit history, OFAC search, landlord/tenant court record 
                    search, criminal record search and registered sex offender search. I authorize the release of 
                    information from previous or current landlords, employers, bank representatives, and personal 
                    references.</p>
                <p>I agree to furnish additional credit and/or personal references upon request. I understand 
                    incomplete or incorrect information provided in this application may cause a delay in processing 
                    which may result in denial of tenancy. This investigation is for resident screening purposes only and is 
                    strictly confidential. I hereby hold Axxelist/Landlord and its agents free and harmless of any liability for 
                    any damages arising out of any improper use of this information.</p>
                <p>Important information about your rights under the Fair Credit reporting Act:</p>
                <ul className="list-inside list-disc">
                    <li>You have a right to request disclosure of the nature and scope of the investigation</li>
                    <li>You must be told if information in your file has been used against you.</li>
                    <li>You have a right to know what is in your file, and this disclosure may be free</li>
                    <li>You have the right to ask for a credit score (there may be a fee for this service)</li>
                    <li>You have the right to dispute incomplete or inaccurate information</li>
                </ul>
                <p>Consumer reporting agencies must correct inaccurate, incomplete, or unverifiable information</p>
                <p>Consumer Response Center, Room 130-A, Federal Trade Commission, 600 Pennsylvania Avenue
                    N.W., Washington D.C. 20580.</p>
                <p>In connection with my application for housing, I understand that Axxelist, the property owner/agent
                    may obtain one or more consumer reports, which may contain public information, for the purposes of
                    evaluating my application. These consumer reports will be obtained from one or more of the following
                    consumer reporting agencies:</p>  
                <ul className="list-inside list-disc">
                    <li>Trans Union, Regional Disclosure Center, 1561 Orangethorpe Ave., Fullerton, CA, 92631, (714)
                    738-3800</li>
                    <li>Equifax, E.C.I.F., P.O. Box 740241, Atlanta, GA, 30374-0241, (800) 685-1111</li>
                    <li>Experian (TRW), Consumer Assistance, P.O. Box 949, Allen, TX, 75002, (888) 397-3742</li>
                </ul>  
                <p>These consumer reports are defined as investigative consumer reports. These reports may contain
                    information on my character, general reputation, personal characteristics, and mode of living. In
                    connection with my application for housing, I authorize Axxelist, owner/agent to obtain a consumer
                    report from the consumer reporting agencies listed above.</p>
            </DialogContent>
            <FeeArea>
                <div>
                    <label><strong>{staticTexts.applicationFee}:</strong></label>
                    <span className="mx-2">$</span>
                    <input name="applicationFee" 
                        type="number"
                        disabled 
                        placeholder="0.00"                     
                        className="border-solid border-b border-gray-300"
                    />
                    {/* <TextField label="Name:" name="applicantName" size="small" /> */}
                </div> 
            </FeeArea>
            <SignsArea>                
                <div className="col-span-1 md:col-span-2">
                    <label><strong>{staticTexts.applicantSignature}:</strong></label>
                    <input name="applicantName" type="text"
                        maxLength={100}
                        className="border-solid border-b border-gray-300 mx-2"
                    />
                    {/* <TextField label="Name:" name="applicantName" size="small" /> */}
                </div>    
                <div> 
                    <label><strong>Date:</strong></label>
                    <input name="signDate" type="date"                             
                        className="border-solid border-b border-gray-300 mx-2" 
                    />
                </div>
            </SignsArea>
            <DialogActions className="mt-4"> 
                <Button onClick={onAcknowledge}>Sign off...</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConsentAcknowledgment;
import { Alert } from 'antd';
import { AuthRole } from 'common/enums/enums';
import { useHasRoles } from 'hooks';
import { useUserProfileValidation } from 'hooks/query';
import { Icon } from 'legacy-components/componets';
import { VerificationErrors } from 'legacy-pages/renter/schedule-tour/config';
import { FC, Fragment, ReactNode } from 'react';

type Props = {
  action?: ReactNode;
};

const UserIncompleteProfileAlert: FC<Props> = () => {
  const { data: userProfileValidation, isLoading } = useUserProfileValidation({
    placeholderData: (previousValue) => previousValue,
  });
  const isLandlord = useHasRoles([AuthRole.Landlord]);

  const getUserHasVerification = () => {
    if (isLandlord && !Boolean(userProfileValidation?.isIdentityEnabled)) {
      return Boolean(userProfileValidation?.isProfileValid);
    }

    return Boolean(userProfileValidation?.isProfileValid && userProfileValidation.isIdentityValid);
  };

  const getIdentityWarnVisibility = () => {
    if (isLandlord && !Boolean(userProfileValidation?.isIdentityEnabled)) {
      return false;
    }

    return !userProfileValidation?.isIdentityValid;
  };

  const isUserVerified = getUserHasVerification();

  if (!userProfileValidation || isLoading || isUserVerified) {
    return null;
  }

  const isEmailNotVerified = userProfileValidation?.errors?.includes(VerificationErrors.ConfirmEmail);
  const isPersonalInfoErr = userProfileValidation?.errors?.includes(VerificationErrors.PersonalInfo);
  const isAddressErr = userProfileValidation?.errors?.includes(VerificationErrors.UserAddress);

  return (
    <Alert
      message={
        <span className='font-bold text-lg'>
          Additional details required for completion of your profile information
        </span>
      }
      description={
        <div>
          Your profile is incomplete. To avoid service limitations, please, fill out your profile with all the required
          information.
          <br />
          {!userProfileValidation.isProfileValid && (
            <Fragment>
              {isPersonalInfoErr && (
                <Fragment>
                  <strong>
                    Please, make sure you fill in these fields: First name, Last name, Date of Birth, Social security number (SSN), Email, Phone
                    number.
                  </strong>
                  <br />
                </Fragment>
              )}
              {isAddressErr && (
                <Fragment>
                  <strong>Please, make sure you fill in Home address field.</strong>
                  <br />
                </Fragment>
              )}
              {isEmailNotVerified && (
                <Fragment>
                  <strong>Please, verify your email address.</strong>
                  <br />
                </Fragment>
              )}
            </Fragment>
          )}
          {getIdentityWarnVisibility() && (
            <Fragment>
              <strong>
                Please, verify your identity, this can be done in the 'Verification' tab on the profile page.
              </strong>{' '}
              <br />
            </Fragment>
          )}
        </div>
      }
      type='warning'
      showIcon
      icon={<Icon name={'danger'} height={24} width={24} />}
    />
  );
};

export { UserIncompleteProfileAlert };

import { InputProps, SelectOption, SelectProps } from 'common/types/types';
import { ErrorMessage, useFormik } from 'formik';
import {
  Avatar,
  Button,
  Checkbox,
  CropImage,
  FormDescription,
  Input,
  Label,
  Loader,
  Modal,
  PhoneInput,
  Select,
} from 'legacy-components/componets';
import { Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  creditObligationDescription,
  creditObligationTypeOptions,
  educationEarnedOptions,
  employmentTypeOptions,
  personalAddressFormDescription,
  personalDetailsFormDescription,
  personalEducationDescription,
  personalEmploymentDescription,
  petsDescription,
  petTypeOptions,
  previousResidentsDescription,
  spouseDetailDescription,
  consentAcknowledgementDescription
} from './config';

import { CustomDatePicker, MultiSelect } from 'legacy-components/fields/fields';
import { renterProfileValidation } from 'validations/renter/renter-profile.validation';
// import { UserDetailsDto } from 'common/types/services/api/user/user-details-dto.type';
import { notification } from 'services/services';
import { PatternFormat } from 'react-number-format';
import { removeEmptyValueObj } from 'helpers/remove-empty-value-obj';
// import { FileValue } from '../renter-verification-details-form/types';
// import { UploadFileCard } from '../renter-verification-details-form/upload-file-card';
// import { fields } from './fields';
import { DocumentsDetailsFormValues, RenterPersonalDetailsValues } from './types';
// import {
//   DocumentsFileType,
//   verificationDocumentsFileTypeToVerificationFormFieldKeyMap,
//   verificationDocumentsFormFieldKeyToVerificationFileTypeMap,
// } from './mappings';
// import { CheckCircleFilled } from '@ant-design/icons';
import { EditTwoTone } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Progress, Space } from 'antd';
import { AxiosProgressEvent } from 'axios';
import { colors } from 'colors';
import {
  phoneNumberDisabledButtonStyles,
  phoneNumberDisabledInputStyles,
} from 'common/styles/phoneNumberDisabledStyles';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import { ConfirmModal } from 'components/atoms/ConfirmModal/ConfirmModal';
import useDeleteProfileImage from 'hooks/profile/use-delete-renter-image';
import { useUserProfile, useUserProfileValidation } from 'hooks/query';
import { useVerifyEmail } from 'hooks/query/user/use-verify-email';
import { useUpdateCurrentUser, useUpdateCurrentUserPhone } from 'hooks/use-user-profile.hook';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';
import { VerificationErrors } from 'legacy-pages/renter/schedule-tour/config';
import {
  // removeUserProfileImage,
  // removeVerificationFile,
  updateUserProfileImage,
} from 'services/api/auth-api.service';
import { Notification } from 'services/notification.service';
import { RenterPersonalDetailsFormAddressField } from './address-field';
import { EmailVerificationModal } from './email-verification-modal';
import { stringAsFloatNumber, stringAsIntegerNumber } from 'helpers/input-field.helper';
import ConsentAcknowledgment from './ConsentAcknowledgment';
import AddOutlined from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';


// export type RenterPersonalDetailsValues = Omit<UserDetailsDto, 'thumbnailUrl' | 'id'> & {
//   image: File | null;
//   driverLicense: FileValue;
//   passport: FileValue;
//   addressField: string;
//   companyAddressField: string;
//   educationAddressField: string;
// };

// type ConvictionDetails = {
//   felonyConviction: string | undefined;
//   felonyDeferredAdjudication: string | undefined;
//   bankruptcyDeclaration: string | undefined;
// };

// type SpouseDetails = {
//   fullName: string;
//   residentAddress: string;
//   phoneNumber: string;
//   dateOfBirth: string;
//   socialSecurityNumber: string;
//   driverLicense: string;
//   employerName: string;
//   employerAddress: string;
//   employmentDate: string;
//   monthlyIncome: number;
// };

// type RenterResidentialDetails = {
//   address: string;
//   city: string;
//   state: string;
//   zip: string;
//   periodMonths: number;
//   periodYears: number;
//   ownerFullName: string;
//   ownerEmail: string;
//   ownerPhoneNumber: string;
// };

// type CreditObligation = {
//   obligationType: string;
//   amount: number;
// };

// export type RenterPersonalDetailsValues = {
//   firstName: string;
//   lastName: string;
//   email: string;
//   phoneNumber: string;
//   dateOfBirth: string;
//   // driversLicenseNumber: string;
//   // driversLicenseState: string;
//   // driversLicenseIssueDate: string;
//   // driversLicenseExpirationDate: string;
//   // socialSecurityNumber: string;
//   gra: string;
//   isSSNNumber: boolean;
//   // passportNumber: string;
//   // passportCountry: string;
//   // passportIssueDate: string;
//   // passportExpirationDate: string;
//   address: string;
//   city: string;
//   state: string;
//   zip: string;
//   residentPeriodMonths: number;
//   residentPeriodYears: number;
//   companyAddress: string;
//   companyCity: string;
//   companyState: string;
//   companyZip: string;
//   previousResidens: RenterResidentialDetails[];
//   educationAddress: string;
//   educationCity: string;
//   educationState: string;
//   educationZip: string;
//   jobTitle: string;
//   employmentType: string;
//   companyName: string;
//   employmentYears: string;
//   employmentMonths: string;
//   householdIncome: string;
//   retired: boolean;
//   selfEmployed: boolean;
//   institutionName: string;
//   educationEarned: string;

//   image: File | null;
//   // driverLicense: FileValue;
//   // passport: FileValue;
//   addressField: string;
//   companyAddressField: string;
//   educationAddressField: string;
//   spouseDetails: SpouseDetails[]; 
//   creditObligations: CreditObligation[];
//   convictions: ConvictionDetails;
//   petsCount: string;
//   petsTypes: string[];
//   petsWeight: string;
// };

//**** Nano control-components to be re-used */
const CheckBox = ({ disabled, value, onChange }) => {
  return (<input type="checkbox" className="w-[18px] h-[18px]" value={value} onChange={onChange} disabled={disabled} />);
}

const RenterPersonalDetailsForm = () => {
  const [isCropImageOpen, setIsCropImageOpen] = useState(false);
  const [isEmailValid, setEmailValidation] = useState<boolean>(true);
  const [dataUoadingPercent, setDataUoadingPercent] = useState<Record<string, number>>({
    driverLicense: 0,
    passport: 0,
    profileImage: 0,
  });
  const [isUpoadingProgressVisible, setIsUpoadingProgressVisible] = useState(false);
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const [isConsentAcknowledgmentOpen, setConsentAcknowledgmentOpen] = useState(false);

  // const addButtonLookAndFeel = {
  //   borderRadius: 15, 
  //   width: 20,
  //   height: 25
  // };

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    setTouched,
    submitForm,
    setValues,
    isSubmitting,
    validateForm,
    isValid,
  } = useFormik<RenterPersonalDetailsValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      image: null,
      phoneNumber: '',
      dateOfBirth: '',
      // driversLicenseNumber: '',
      // driversLicenseState: '',
      // driversLicenseIssueDate: '',
      // driversLicenseExpirationDate: '',
      // driverLicense: {},

      socialSecurityNumber: '',
      gra: 'yes',
      // isSSNNumber: false,
      // passportNumber: '',
      // passportCountry: '',
      // passportIssueDate: '',
      // passportExpirationDate: '',
      // passport: {},

      addressField: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      
      periodMonths: 0,
      periodYears: 0,

      companyAddressField: '',
      companyAddress: '',
      companyCity: '',
      companyState: '',
      companyZip: '',

      rentalHistory: [{
        historyId: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        ownerFullName: '',
        ownerPhoneNumber: '',
        ownerEmail: '',
        periodMonths: 0,
        periodYears: 0
      }],

      rentalPartners: [{
        partnerId: '',
        fullName: '',
        residentAddress: '',
        phoneNumber: '',
        dateOfBirth: '',
        socialSecurityNumber: '',
        driverLicense: '',
        employerName: '',
        employerAddress: '',
        employmentDate: '',
        monthlyIncome: 0
      }],

      educationAddressField: '',
      educationAddress: '',
      educationCity: '',
      educationState: '',
      educationZip: '',

      jobTitle: '',
      employmentType: '',
      companyName: '',
      employmentYears: '',
      employmentMonths: '',

      householdIncome: '',
      retired: false,
      selfEmployed: false,
      institutionName: '',
      educationEarned: '',

      creditObligations: [{
        obligationId: '', 
        obligationType: '', 
        amount: 0
      }],

      convictions: {
        felonyConviction: '', 
        felonyDeferredAdjudication: '', 
        bankruptcyDeclaration: ''
      },

      petsCount: '',
      petsTypes: [],
      petsWeight: '',
    },
    onSubmit: async (values) => await handleFormSubmit(values),
    validationSchema: renterProfileValidation,
  });
  const queryClient = useQueryClient();
  const { data: userProfile, isLoading: isUserProfileLoading } = useUserProfile();
  const { data: userProfileVerification } = useUserProfileValidation();
  const { mutateAsync: verifyEmail, isPending: isVerificationOfEmail } = useVerifyEmail({
    onSuccess: () => {
      const notification = new Notification();
      notification.success('The email for verification has been sent to your inbox.');
    },
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'You could not confirm email');
    },
  });
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser({
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'One of these fields is invalid: First Name, Last Name, DoB.');
    },
  });
  // const { mutateAsync: updateUserProfile } = useUpdateUserProfile({
  //   onError: (e) => {
  //     const notification = new Notification();
  //     notification.error(e?.response?.data?.detail || 'User details is not updated');
  //   },
  // });
  const { mutateAsync: updateCurrentUserPhone } = useUpdateCurrentUserPhone({
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'User phone is not updated');
    },
  });
  // const { data: verificationFiles, isLoading: isVerificationFilesLoading } = useVerificationFiles();
  const [imageUrl, setImageUrl] = useState('');
  // const pageLoading = isUserProfileLoading || isVerificationFilesLoading;
  const pageLoading = isUserProfileLoading;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const updateDataUoadingPercent = (key: string, progress: AxiosProgressEvent) => {
    setDataUoadingPercent((prevState) => {
      return {
        ...prevState,
        [key]: progress.total ? Math.floor((progress.loaded * 100) / progress.total) : 0,
      };
    });
  };

  const totalDataUoadingPercent = useMemo<number>(() => {
    const values = Object.values<number>(dataUoadingPercent);
    return Math.floor(values.reduce((accumulator: number, value: number) => accumulator + value, 0) / values.length);
  }, [dataUoadingPercent]);

  const getCommonInputProps = (
    name: keyof Omit<
      RenterPersonalDetailsValues,
      // | 'isSSNNumber'
      | 'retired'
      | 'selfEmployed'
      | 'driversLicenseIssueDate'
      | 'driversLicenseExpirationDate'
      | 'passportIssueDate'
      | 'passportExpirationDate'
    >,
    placeholder: string,
  ): InputProps => {
    return {
      name,
      placeholder,
      // @ts-ignore TODO: FIX IGNORE
      value: values[name],
      onChange: handleChange,
      invalid: Boolean(errors[name] && touched[name]),
    };
  };

  const showError = (fieldName: keyof RenterPersonalDetailsValues): boolean => {
    return Boolean(errors[fieldName] && touched[fieldName]);
  };

  const getSelectProps = (
    name: keyof Pick<RenterPersonalDetailsValues, 'employmentType' | 'educationEarned'>,
    options: SelectOption[],
    placeholder: string,
  ): SelectProps => {
    return {
      name,
      options,
      placeholder,
      value: values[name],
      onChange: (value: any) => setFieldValue(name, value),
      selectStyle: { singleValue: { paddingLeft: 4 }, placeholder: { paddingLeft: 4 } },
    };
  };

  const formatAddress = (value: { address: string; city: string; state: string; zip: string }) => {
    return [value.address, value.state, value.city, value.zip].filter(Boolean).join(', ');
  };

  const handleFormSubmit = async (values: RenterPersonalDetailsValues) => {
    // const selectedFiles = {
    //   passport: values.passport,
    //   driverLicense: values.driverLicense,
    // };

    // const filesToUpload = Object.entries(selectedFiles).filter(([, value]) => value.file);

    // setDataUoadingPercent(() => {
    //   const filesToUploadPercentList = filesToUpload.reduce((acc, [key]) => {
    //     acc[key] = 0;
    //     return acc;
    //   }, {} as Record<string, number>);

    //   if (values.image) {
    //     filesToUploadPercentList.profileImage = 0;
    //   }

    //   return {
    //     ...filesToUploadPercentList,
    //   };
    // });

    await Promise.all([
      updateCurrentUser(values),
      // updateUserProfile(values),
      updateCurrentUserPhone({ phoneNumber: values.phoneNumber }),
    ]);
    // if (values.image) {
    //   setIsUpoadingProgressVisible(true);
    //   await updateUserProfileImage(values.image, (progressEvent) => {
    //     updateDataUoadingPercent('profileImage', progressEvent);
    //   });
    // }
    // if (!values.image && !imageUrl) {
    //   await removeUserProfileImage();
    // }

    // await Promise.all(
    //   filesToUpload.map(([name, { file }]) => {
    //     setIsUpoadingProgressVisible(true);
    //     const type =
    //       verificationDocumentsFormFieldKeyToVerificationFileTypeMap[name as keyof DocumentsDetailsFormValues];
    //     return uploadVerificationFile({
    //       type,
    //       file: file as File,
    //       onUploadProgress(progressEvent) {
    //         updateDataUoadingPercent(name, progressEvent);
    //       },
    //     });
    //   }),
    // );

    // const filesToRemove = Object.entries(selectedFiles).filter(([, value]) => !!value.id && !value.file && !value.url);
    // await Promise.all(filesToRemove.map(([, file]) => removeVerificationFile(file.id as string)));
    await queryClient.invalidateQueries({ queryKey: ['user'] });
    notification.success('Thank you for updating your profile. Your changes are now saved.');
    setIsUpoadingProgressVisible(false);
  };

  const { mutateAsync: deleteProfileImage, isPending: isLoadingRemovingImg } = useDeleteProfileImage();
  const removeImage = async () => {
    await deleteProfileImage();
    setFieldValue('image', null);
    setImageUrl('');
  };

  const addImage = async (image: File) => {
    setIsUpoadingProgressVisible(true);
    await updateUserProfileImage(image, (progressEvent) => {
      updateDataUoadingPercent('profileImage', progressEvent);
    });
    setFieldValue('image', image);
    setImageUrl(URL.createObjectURL(image));
    await queryClient.invalidateQueries({ queryKey: ['user'] });
  };

  const handleCloseModal = () => {
    setIsCropImageOpen(false);
  };

  const handleCalendarChange = (fieldName: keyof RenterPersonalDetailsValues | string , date: Date | null) => {
    setFieldValue(fieldName, date?.toDateString()).then(() => {
      setTouched({ ...touched, [fieldName]: true }, true);
    });
  };

  // useEffect(() => {
  //   if (!verificationFiles) return;
  //   verificationFiles.forEach((file: any) => {
  //     const fieldValue: FileValue = { url: file.fileUrl, id: file.id };

  //     const type = file.type as DocumentsFileType;
  //     const field = verificationDocumentsFileTypeToVerificationFormFieldKeyMap[type];

  //     setFieldValue(field, fieldValue);
  //   });
  // }, [verificationFiles]);

  useLayoutEffect(() => {
    if (!userProfile) return;
    const userProfileFiltered = removeEmptyValueObj(userProfile) as RenterPersonalDetailsValues;
    setValues({
      ...values,
      ...userProfileFiltered,
      addressField: formatAddress({
        address: userProfileFiltered.address,
        state: userProfileFiltered.state,
        city: userProfileFiltered.city,
        zip: userProfileFiltered.zip,
      }),
      educationAddressField: formatAddress({
        address: userProfileFiltered.educationAddress,
        state: userProfileFiltered.educationState,
        city: userProfileFiltered.educationCity,
        zip: userProfileFiltered.educationZip,
      }),
      companyAddressField: formatAddress({
        address: userProfileFiltered.companyAddress,
        state: userProfileFiltered.companyState,
        city: userProfileFiltered.companyCity,
        zip: userProfileFiltered.companyZip,
      }),
      image: null,
    });
    setImageUrl(userProfile.thumbnailUrl);
  }, [userProfile]);

  const isEmailHasChanged = userProfile?.email && values["email"] && userProfile?.email !== values["email"];

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, [imageUrl]);

  useEffect(() => {
    if (values.retired) {
      setValues({
        ...values,
        jobTitle: '',
        employmentType: '',
        companyName: '',
        employmentYears: '',
        employmentMonths: '',
        companyAddressField: '',
        companyAddress: '',
        companyState: '',
        companyCity: '',
        companyZip: '',
        householdIncome: '',
      });
    }
  }, [values.retired]);

  useEffect(() => {
    validateForm();
  }, [values.addressField, values.educationAddressField, values.companyAddressField]);

  useEffect(() => {
    if (userProfileVerification?.errors && !userProfileVerification?.isProfileValid) {
      const isEmailNotVerified = userProfileVerification.errors.includes(VerificationErrors.ConfirmEmail);
      isEmailNotVerified ? setEmailValidation(false) : setEmailValidation(true);
    }
  }, [userProfileVerification]);

  // const updateFileCard = async (file: File, name: keyof DocumentsDetailsFormValues) => {
  //   setFieldValue(name, { ...values[name], url: URL.createObjectURL(file), file });
  // };

  // const deleteFileCard = async (name: keyof DocumentsDetailsFormValues) => {
  //   setFieldValue(name, { id: values[name].id });
  // };

  // const driversLicenseSection = () => {
  //   const driverFile = fields.at(1)!;

  //   return (
  //     <div className='flex flex-col gap-4'>
  //       <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
  //         <div className='w-full'>
  //           <Label label={'Drivers License Number'}>
  //             <Input {...getCommonInputProps('driversLicenseNumber', '***1412313PS')} />
  //           </Label>
  //         </div>
  //         <div className='w-full'>
  //           <Label label={'Drivers License State'}>
  //             <Select {...getSelectProps('driversLicenseState', statesSelectOptions, 'State')} />
  //           </Label>
  //         </div>
  //       </div>
  //       <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
  //         <div className='w-full'>
  //           <Label label={'Drivers License Issue Date'}>
  //             <CustomDatePicker
  //               name='driversLicenseIssueDate'
  //               value={values.driversLicenseIssueDate}
  //               onChange={(date) => handleCalendarChange('driversLicenseIssueDate', date)}
  //               maxDate={new Date()}
  //               minDate={values.dateOfBirth ?? null}
  //               invalid={Boolean(errors.driversLicenseIssueDate && touched.driversLicenseIssueDate)}
  //             />
  //             {showError('driversLicenseIssueDate') && (
  //               <p className='text-xs text-error py-1'>{errors.driversLicenseIssueDate}</p>
  //             )}
  //           </Label>
  //         </div>

  //         <div className='w-full'>
  //           <Label label={'Drivers License Expiration Date'}>
  //             <CustomDatePicker
  //               name='driversLicenseExpirationDate'
  //               value={values.driversLicenseExpirationDate}
  //               onChange={(date) => handleCalendarChange('driversLicenseExpirationDate', date)}
  //               minDate={new Date()}
  //               invalid={Boolean(errors.driversLicenseExpirationDate && touched.driversLicenseExpirationDate)}
  //             />
  //             {showError('driversLicenseExpirationDate') && (
  //               <p className='text-xs text-error py-1'>{errors.driversLicenseExpirationDate}</p>
  //             )}
  //           </Label>
  //         </div>
  //       </div>

  //       <UploadFileCard
  //         type={driverFile.type}
  //         title={driverFile.title}
  //         format={driverFile.format}
  //         onChange={async (file) => {
  //           updateFileCard(file, driverFile.name);
  //         }}
  //         name={driverFile.name}
  //         fileValue={values[driverFile.name]}
  //         error={errors[driverFile.name]?.file}
  //         onDelete={async () => {
  //           deleteFileCard(driverFile.name);
  //         }}
  //       />
  //     </div>
  //   );
  // };

  // const passportSection = () => {
  //   const passportFile = fields.at(0)!;
  //   return (
  //     <div className='flex flex-col gap-4'>
  //       <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
  //         <div className='w-full'>
  //           <Label label={'Passport Number'}>
  //             <Input {...getCommonInputProps('passportNumber', 'Passport Number')} />
  //           </Label>
  //         </div>
  //         <div className='w-full'>
  //           <Label label={'Passport Country'}>
  //             <Input {...getCommonInputProps('passportCountry', 'Passport Country')} />
  //           </Label>
  //         </div>
  //       </div>
  //       <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
  //         <div className='w-full'>
  //           <Label label={'Passport Issue Date'}>
  //             <CustomDatePicker
  //               name='passportIssueDate'
  //               value={values.passportIssueDate}
  //               onChange={(date) => handleCalendarChange('passportIssueDate', date)}
  //               maxDate={new Date()}
  //               minDate={values.dateOfBirth ?? null}
  //               invalid={Boolean(errors.passportIssueDate && touched.passportIssueDate)}
  //             />
  //             {showError('passportIssueDate') && <p className='text-xs text-error py-1'>{errors.passportIssueDate}</p>}
  //           </Label>
  //         </div>
  //         <div className='w-full'>
  //           <Label label={'Passport Expiration Date'}>
  //             <CustomDatePicker
  //               name='passportExpirationDate'
  //               placeholder='MM-DD-YYYY'
  //               value={values.passportExpirationDate}
  //               minDate={new Date()}
  //               onChange={(date) => handleCalendarChange('passportExpirationDate', date)}
  //               invalid={Boolean(errors.passportExpirationDate && touched.passportExpirationDate)}
  //             />
  //             {showError('passportExpirationDate') && (
  //               <p className='text-xs text-error py-1'>{errors.passportExpirationDate}</p>
  //             )}
  //           </Label>
  //         </div>
  //       </div>
  //       <UploadFileCard
  //         type={passportFile.type}
  //         title={passportFile.title}
  //         format={passportFile.format}
  //         onChange={(file) => {
  //           updateFileCard(file, passportFile.name);
  //         }}
  //         name={passportFile.name}
  //         fileValue={values[passportFile.name]}
  //         error={errors[passportFile.name]?.file}
  //         onDelete={async () => {
  //           deleteFileCard(passportFile.name);
  //         }}
  //       />
  //     </div>
  //   );
  // };

  // const ssnSection = () => {
  //   return (
  //     <>
  //       <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
  //         <div className='w-full'>
  //           <Label label={'Social Security Number'}>
  //             <PatternFormat
  //               format='###-##-####'
  //               name='socialSecurityNumber'
  //               placeholder='111-11-****'
  //               value={values['socialSecurityNumber']}
  //               onChange={(e) => {
  //                 const numValue = e.target.value.replaceAll('-', '').replaceAll('_', '');
  //                 setFieldValue('socialSecurityNumber', numValue).then(() => {
  //                   setTouched({ socialSecurityNumber: true }, true);
  //                 });
  //               }}
  //               invalid={Boolean(errors['socialSecurityNumber'] && touched['socialSecurityNumber'])}
  //               customInput={Input}
  //               valueIsNumericString
  //               mask='_'
  //             />
  //             {showError('socialSecurityNumber') && (
  //               <p className='text-xs text-error py-1'>{errors.socialSecurityNumber}</p>
  //             )}
  //           </Label>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  // const [activeTab, setActiveTab] = useState(1);
  // const handleTabClick = (tabNumber: number) => {
  //   setActiveTab(tabNumber);
  // };

  // const renderTabContent = () => {
  //   switch (activeTab) {
  //     case 1:
  //       return driversLicenseSection();
  //     case 2:
  //       return passportSection();
  //     case 3:
  //       return ssnSection();
  //     default:
  //       return <></>;
  //   }
  // };

  // function changeInvalidDocumentTab(obj: any) {
  //   for (let key in obj) {
  //     // driver - any field related to driversLicense
  //     if (key.includes('driver')) {
  //       setActiveTab(1);
  //       return;
  //     }
  //     // passport - any field related to passport data
  //     if (key.includes('passport')) {
  //       setActiveTab(2);
  //       return;
  //     }
  //     if (key.includes('socialSecurityNumber')) {
  //       setActiveTab(3);
  //       return;
  //     }
  //   }
  // }

  // function isFieldInvalid(nameOfValue: string) {
  //   for (let key in errors) {
  //     if (key.includes(nameOfValue)) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // const getTabColor = (isTabActive: boolean, validCondition: boolean, nameOfValue: string) => {
  //   if (isTabActive) {
  //     if (!isValid && isFieldInvalid(nameOfValue)) {
  //       return 'red';
  //     }

  //     if (validCondition) {
  //       return 'green';
  //     }

  //     return 'primary';
  //   }

  //   if (isFieldInvalid(nameOfValue)) {
  //     return 'red-outline';
  //   }

  //   return 'primary-outline';
  // };

  // const renderTabs = () => {
  //   const determineDriverLicenseClass = () => {
  //     const isTabActive = activeTab === 1;
  //     const hasDriverLicense = values['driverLicense']?.url;
  //     const hasRequiredValues =
  //       values.driversLicenseExpirationDate &&
  //       values.driversLicenseIssueDate &&
  //       values.driversLicenseNumber &&
  //       values.driversLicenseState;
  //     return getTabColor(isTabActive, Boolean(hasDriverLicense && hasRequiredValues), 'driver');
  //   };

  //   const determinePassportClass = () => {
  //     const isTabActive = activeTab === 2;
  //     const hasDriverLicense = values['passport']?.url;
  //     const hasRequiredValues =
  //       values.passportExpirationDate && values.passportIssueDate && values.passportCountry && values.passportNumber;
  //     return getTabColor(isTabActive, Boolean(hasDriverLicense && hasRequiredValues), 'passport');
  //   };

  //   const driverTabTheme = determineDriverLicenseClass();
  //   const passportTabTheme = determinePassportClass();
  //   const ssnTabTheme = getTabColor(activeTab === 3, false, 'socialSecurityNumber');
  //   const icon = <CheckCircleFilled className={'text-white  text-[20px]'} />;

  //   return (
  //     <>
  //       <div className='flex flex-col gap-3'>
  //         <FormDescription {...documentFormDescription} />
  //         <div className='flex gap-3 flex-wrap'>
  //           <Button
  //             onClick={() => handleTabClick(1)}
  //             label={'Drivers License'}
  //             theme={driverTabTheme}
  //             startIcon={driverTabTheme === 'green' ? icon : null}
  //           />
  //           <Button
  //             onClick={() => handleTabClick(2)}
  //             label={'Passport Number'}
  //             theme={passportTabTheme}
  //             startIcon={passportTabTheme === 'green' ? icon : null}
  //           />
  //           <Button onClick={() => handleTabClick(3)} label={'SSN'} theme={ssnTabTheme} />
  //         </div>
  //         <div className='tab-content'>{renderTabContent()}</div>
  //       </div>
  //     </>
  //   );
  // };

    // handle adding record of Spouse / Partner details
  const onPartnerConvictionOptionChanged = (conviction, value) => {
    values.convictions[conviction]=value;

    setValues({...values, 'convictions': values.convictions});
  }

  // handle adding record of Previous Residence
  const handleAddRentalHistory = () => {
    let rentalHistory = values.rentalHistory;
    
    rentalHistory.push({
      historyId:'', address: '', city: '', state: '', zip: '',
      ownerFullName: '', ownerPhoneNumber: '', ownerEmail: '',
      periodMonths: 1, periodYears: 0
    });
    
    setValues({...values, rentalHistory});
  }

  const onRemoveResidenceDetailElement = (element, index) => {
    let rentalHistory = values.rentalHistory;

    if(rentalHistory.length > 1)
      rentalHistory.splice(index, 1);

    setValues({...values, rentalHistory});
  }

  // handle adding record of Spouse Details
  const onAddSpouseDetail = () => {
    let rentalPartners = values.rentalPartners;
    
    rentalPartners.push({
      partnerId:'', fullName: '', residentAddress: '',
      dateOfBirth: '', phoneNumber: '',
      socialSecurityNumber: '',  driverLicense: '',
      employerName: '', employerAddress: '',
      employmentDate: '', monthlyIncome: 0
    });
    
    setValues({...values, rentalPartners});
  }

  const onRemovePartnerDetailElement = (element, index) => {
    let rentalPartners = values.rentalPartners;

    if(rentalPartners.length > 1)
      rentalPartners.splice(index, 1);

    setValues({...values, rentalPartners});
  }


  const onAddNewCreditObligation = () => {
    let creditObligations = values.creditObligations;
    
    creditObligations.push({obligationId: '', obligationType: '', amount: 0 });
    
    setValues({...values, creditObligations});
  }

  const onRemoveCreditObligation = (element, index) => {
    let creditObligations = values.creditObligations;

    if(creditObligations.length > 1)
      creditObligations.splice(index, 1);

    setValues({...values, creditObligations});
  }

  const handleOpenConsentAcknowledgment = () => {
    if(!isConsentAcknowledgmentOpen)
      setConsentAcknowledgmentOpen(!isConsentAcknowledgmentOpen);
  }

  const handleCloseConsentAcknowledgment = () => {
    if(isConsentAcknowledgmentOpen)
      setConsentAcknowledgmentOpen(!isConsentAcknowledgmentOpen);
  }

  //** RENDERING */

  return (
    <div className='flex flex-col gap-0 md:gap-9 mb-5 flex-1'>
      <Modal isOpen={isCropImageOpen} onClose={handleCloseModal} styles={{ content: { borderRadius: 15 } }}>
        <CropImage title={'Edit Photo'} onClose={handleCloseModal} onCrop={addImage} />
      </Modal>

      <ConfirmModal
        open={confirmModalOpen}
        onOk={async () => {
          await removeImage();
          setConfirmModalOpen(false);
        }}
        onCancel={() => setConfirmModalOpen(false)}
        title='Do you want to delete your profile photo ?'
        isLoadingSubmitBtn={isLoadingRemovingImg}
      />

      <ConsentAcknowledgment isOpen={isConsentAcknowledgmentOpen} 
          onAcknowledge={handleCloseConsentAcknowledgment}
          onClose={handleCloseConsentAcknowledgment} 
      />

      <div className='personal-details w-full flex flex-col gap-9 px-4 md:px-[34px] py-4 md:py-6 shadow-white-xl'>
        <FormDescription {...personalDetailsFormDescription} />
        {pageLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className='personal-details-form'>
              <div className='flex flex-col gap-[18px]'>
                <Label label={'Profile photo'}>
                  <div className={'flex justify-start gap-5'}>
                    <Avatar src={imageUrl} />
                    {!imageUrl && <Button onClick={() => setIsCropImageOpen(true)} label={'Add photo'} />}
                    {imageUrl && (
                      <Button theme={'red'} onClick={() => setConfirmModalOpen(true)} label={'Delete photo'} />
                    )}
                  </div>
                </Label>

                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                  <div className='w-full'>
                    <Label label={'First Name *'}>
                      <Input {...getCommonInputProps('firstName', 'First Name')} />
                      {showError('firstName') && <p className='text-xs text-error py-1'>{errors.firstName}</p>}
                    </Label>
                  </div>
                  <div className='w-full'>
                    <Label label={'Last Name *'}>
                      <Input {...getCommonInputProps('lastName', 'Last Name')} />
                      {showError('lastName') && <p className='text-xs text-error py-1'>{errors.lastName}</p>}
                    </Label>
                  </div>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                  <Label label={'Date of Birth *'}>
                    <CustomDatePicker
                      name='dateOfBirth'
                      value={values.dateOfBirth}
                      onChange={(date) => handleCalendarChange('dateOfBirth', date)}
                      invalid={Boolean(errors['dateOfBirth'] && touched['dateOfBirth'])}
                    />
                    {showError('dateOfBirth') && <p className='text-xs text-error py-1'>{errors.dateOfBirth}</p>}
                  </Label>

                  <Label label={'Social Security Number'}>
                    {' '}
                    <PatternFormat
                      format='###-##-####'
                      name='socialSecurityNumber'
                      placeholder='999-99-9999'
                      value={values['socialSecurityNumber']}
                      onChange={(e) => {
                        const numValue = e.target.value.replaceAll('-', '').replaceAll('_', '');
                        setFieldValue('socialSecurityNumber', numValue).then(() => {
                          setTouched({ socialSecurityNumber: true }, true);
                        });
                      }}
                      invalid={Boolean(errors['socialSecurityNumber'] && touched['socialSecurityNumber'])}
                      customInput={Input}
                      valueIsNumericString
                      mask='_'
                    />{' '}
                    {showError('socialSecurityNumber') && (
                      <p className='text-xs text-error py-1'>{errors.socialSecurityNumber}</p>
                    )}{' '}
                  </Label>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                  <div className='w-full'>
                    <Label label={'Email *'}>
                      <Input
                        iconComponent={<EditTwoTone onClick={() => setIsVerifyEmailModalOpen(true)} />}
                        {...getCommonInputProps('email', 'Email')}
                        stylesConfig={{ inputStyle: { width: '90%', display: 'inline', marginRight: '7px' } }}
                        disabled={true}
                      />
                      <EmailVerificationModal
                        userInfo={userProfile}
                        setIsVerifyEmailModalOpen={setIsVerifyEmailModalOpen}
                        isVerifyEmailModalOpen={isVerifyEmailModalOpen}
                        onCloseCallback={(newEmail) => setFieldValue('email', newEmail)}
                      />
                      {showError('email') && <p className='text-xs text-error py-1'>{errors.email}</p>}
                      {!(userProfileVerification && isEmailValid) && (
                        <div className='flex flex-wrap items-center gap-3 mt-4'>
                          <p className='text-error'>Email verification missing</p>
                          <Button
                            theme='red-outline'
                            label={'Verify'}
                            onClick={async () => await verifyEmail()}
                            disabled={isVerificationOfEmail}
                          ></Button>
                        </div>
                      )}
                    </Label>
                  </div>

                  <div className='w-full'>
                    <Label label={'Phone Number *'}>
                      <ErrorValidationMessage touched={touched.phoneNumber} message={errors.phoneNumber}>
                        <PhoneInput
                          isValid={!Boolean(errors.phoneNumber && touched.phoneNumber)}
                          value={values.phoneNumber}
                          onChange={(value) => {
                            setFieldValue('phoneNumber', value);
                          }}
                          // TODO: CHANGE WHEN WE WILL ADD FLOW TO UPDATE NUMBER FROM PROFILE
                          disabled
                          inputStyle={phoneNumberDisabledInputStyles}
                          disableDropdown
                          buttonStyle={phoneNumberDisabledButtonStyles}
                        />
                      </ErrorValidationMessage>
                    </Label>
                  </div>
                </div>
                {/* {renderTabs()} */}
              </div>
            </div>

            <div className='address-form'>
              <FormDescription {...personalAddressFormDescription} />
              <div className='flex flex-col gap-[18px] pt-4'>
              <div className='grid grid-cols-2 lg:grid-cols-2 gap-3'>
                  <div className="w-full">
                    <Label label={'Home address'}>
                        <RenterPersonalDetailsFormAddressField
                          value={values.addressField}
                          setValue={(value) => {
                            setFieldValue('addressField', value.address);
                            if (!isNotEmptyString(value.address)) {
                              setFieldValue('address', '');
                              setFieldValue('city', '');
                              setFieldValue('state', '');
                              setFieldValue('zip', '');
                            }
                          }}
                          onSelectAddress={(value) => {
                            setFieldValue('address', `${value.streetName} ${value.streetNumber}`);
                            setFieldValue('city', `${value.city}`);
                            setFieldValue('state', `${value.state}`);
                            setFieldValue('zip', `${value.zip}`);
                            setFieldValue(
                              'addressField',
                              formatAddress({
                                address: `${value.streetName}${value.streetNumber ? ' ' + value.streetNumber : ''}`,
                                city: value.city,
                                state: value.state,
                                zip: value.zip,
                              }),
                            );
                          }}
                          fieldName={'addressField'}
                          invalid={Boolean(errors.addressField && touched.addressField)}
                        />
                        {Boolean(errors.addressField && touched.addressField) && (
                          <div className='text-red-500 text-sm text-red font-medium mt-2'>{errors.addressField}</div>
                        )}               
                    </Label>
                  </div>
                  <div className="w-full">
                    <div className='grid grid-cols-2 lg:grid-cols-2 gap-2'>
                    <div className="w-full">
                        <Label label="Years" />
                        <Input
                          {...getCommonInputProps('periodYears', 'Years')}
                          type="number"
                          min={0}
                          disabled={values.retired} 
                          onChange={(e) => stringAsIntegerNumber(e, 'periodYears', setFieldValue)} />
                        {showError('periodYears') && (
                          <p className='text-xs text-error py-1'>{errors.periodYears}</p>
                        )}
                      </div>                      
                      <div className="w-full">
                        <Label label="Months" />                    
                          <Input
                            {...getCommonInputProps('periodMonths', 'Months')}
                            type="number"
                            min={0}
                            onChange={(e) => stringAsIntegerNumber(e, 'periodMonths', setFieldValue)} />
                          {showError('periodMonths') && (
                            <p className='text-xs text-error py-1'>{errors.periodMonths}</p>
                          )}
                      </div>                      
                    </div>   
                  </div>                
                </div>
              </div>
            </div>
          
          {/* BEGIN PREVIOUS RESIDENTIAL ADDRESS */}

          <div className="previous-residents">
            <FormDescription {...previousResidentsDescription} />         
            <div className='flex flex-col gap-[8px] pt-4'>
              {values.rentalHistory && values.rentalHistory.map((residence, index) => {
                return (
                  <div key={index} className="grid grid-cols-none md:grid-cols-6 gap-3">                
                    <div className="col-span-none md:col-span-6 relative">
                      <button type="button" title="Remove"
                        className={"absolute top-0 right-0 bg-slate-100 hover:bg-rose-200 w-[24px] rounded"}
                        onClick={(e) => onRemoveResidenceDetailElement(residence, index)}
                      >
                        <DeleteOutlinedIcon fontSize="small" sx={{ color: 'gray' }}/>
                      </button>
                    </div> 
                    <div className="col-span-none md:col-span-4">
                      <Label label="Address" />
                      <RenterPersonalDetailsFormAddressField
                        value={values.rentalHistory[index].address}
                        setValue={(value) => {
                          setFieldValue('rentalHistory['+index+'].address', value.address);
                        }}
                        onSelectAddress={(value) => {
                          setFieldValue(`rentalHistory.${index}.city`, value.city);
                          setFieldValue(`rentalHistory.${index}.state`, value.state);
                          setFieldValue(`rentalHistory.${index}.zipCode`, value.zip);
                          
                          setFieldValue(`rentalHistory.${index}.address`,
                           `${value.streetName}${value.streetNumber ? ' ' + value.streetNumber : ''}`);
                        }}
                        fieldName={`rentalHistory[${index}].address`}
                        /*invalid={Boolean(errors.addressField && touched.addressField)*/
                      />
                      {/* <ErrorMessage name={`rentalHistory.${index}.address`} /> */}
                    </div>

                    <div>
                      <Label label="Years" />
                      <Input name={`rentalHistory[${index}].periodYears`}
                        type="number"
                        min={0}
                        value={values.rentalHistory[index].periodYears}
                        onChange={(e) => stringAsIntegerNumber(e, `rentalHistory.${index}.periodYears`, setFieldValue)}
                      />
                      {/* <ErrorMessage name={`rentalHistory[${index}].periodYears`} /> */}
                    </div>
                    
                    <div>
                      <Label label="Months" />                    
                        <Input name={`rentalHistory[${index}]periodMonths`} 
                          type="number"
                          min={0}
                          value={values.rentalHistory[index].periodMonths}
                          disabled={values.retired} 
                          onChange={(e) => stringAsIntegerNumber(e, `rentalHistory.${index}.periodMonths`, setFieldValue)}
                      />
                      {/* <ErrorMessage name={`rentalHistory[${index}].periodMonths`} /> */}
                    </div>
              
                    <div className="col-span-none md:col-span-2">
                          <Label label={'Owner full name'} />
                          <Input name={''} maxLength={100}
                            value={values.rentalHistory[index].ownerFullName}
                            disabled={values.retired} 
                            onChange={(e) => setFieldValue('rentalHistory['+index+'].ownerFullName', e.target.value)} />
                          {showError('periodMonths') && (
                            <p className='text-xs text-error py-1'>{errors.periodMonths}</p>
                          )}
                    </div>
                    
                    <div className="col-span-none md:col-span-2">
                      <Label label={'Email address'} />
                      <Input name={`rentalHistory.${index}.ownerEmail`} maxLength={255}
                        value={values.rentalHistory[index].ownerEmail}
                        disabled={values.retired} 
                        onChange={(e) => setFieldValue('rentalHistory.['+index+'].ownerEmail', e.target.value)}
                      />
                      {/* <ErrorMessage name={`rentalHistory[${index}].ownerEmail`}  />   */}
                    </div>
            
                    <div className="col-span-none md:col-span-2">
                      <Label label={'Phone number'} />
                      <PhoneInput
                        value={values.rentalHistory[index].ownerPhoneNumber}
                        onChange={(value) => setFieldValue(`rentalHistory.${index}.ownerPhoneNumber`, value)}
                      />                         
                      {/* <ErrorMessage name={`rentalHistory[${index}].ownerPhoneNumber`}  />  */}
                    </div>  
                  </div>
                )
              })}            
              <div>
                <button title="Add new entry..." onClick={handleAddRentalHistory}>
                  <AddOutlined color="primary" fontSize="medium" />
                </button>
              </div>
            </div>          
          </div>

          {/* END OF PREVIOUS RESIDENTIAL ADDRESS */}


          {/* BEGIN PARTNER / ADDITIONAL OCCUPANT DETAILS */}

          <div className="partners-details">
            <FormDescription {...spouseDetailDescription} />         
            <div className='flex flex-col gap-[8px] pt-4'>
              {values.rentalPartners && values.rentalPartners.map((spouseDetail, index) => {
                return (
                  <div key={index} className="grid grid-cols-none md:grid-cols-3 gap-4" > 
                    <div className="col-span-none md:col-span-3 relative">
                      <button type="button" title="Remove"
                        className={"absolute top-0 right-0 bg-slate-100 hover:bg-rose-200 w-[24px] rounded"}
                        onClick={(e) => onRemovePartnerDetailElement(spouseDetail, index)}
                      >
                        <DeleteOutlinedIcon fontSize="small" sx={{ color: 'gray' }}/>
                      </button>
                    </div>               
                    <div className="col-span-1 md:col-span-2">
                      <Label label={'Full name'}>
                        <Input name={`rentalPartners.${index}.fullName`} maxLength={100}
                          value={values.rentalPartners[index].fullName}
                          onChange={(e) => setFieldValue('rentalPartners['+index+'].fullName', e.target.value, true)}
                        />
                        {/* <ErrorMessage name={`spouseDetails.${index}.fullName`} /> */}
                      </Label>
                    </div>

                    <div>
                      <Label label={'Date of Birth'}>
                        <CustomDatePicker
                          name={`spouseDetails.${index}.dateOfBirth`}
                          value={values.rentalPartners[index].dateOfBirth}
                          onChange={(date) => handleCalendarChange(`spouseDetails.${index}.dateOfBirth`, date)}
                        />
                        {/* <ErrorMessage name={`spouseDetails.${index}.dateOfBirth`} /> */}
                      </Label>
                    </div>

                    <div>
                      <Label label="Phone number">
                      <PhoneInput
                          value={values.rentalPartners[index].phoneNumber}
                          onChange={(value) => setFieldValue('spouseDetails['+index+'].phoneNumber', value)}
                      />
                      {/* <ErrorMessage name={`spouseDetails.${index}.phoneNumber`} /> */}
                      </Label>
                    </div>

                    <div>
                      <Label label="Security number">
                        <Input name={`rentalPartners.${index}.socialSecurityNumber`} 
                          maxLength={30}
                          value={values.rentalPartners[index].socialSecurityNumber}
                          onChange={(e) => setFieldValue('rentalPartners['+index+'].socialSecurityNumber', e.target.value)}
                        />
                        {/* <ErrorMessage name={`spouseDetails.${index}.socialSecurityNumber`} /> */}
                      </Label>
                    </div>

                    <div>
                      <Label label="Drivers license">
                        <Input name={`rentalPartners.${index}.driverLicense`} 
                          maxLength={50}
                          value={values.rentalPartners[index].driverLicense}
                          onChange={(e) => setFieldValue('spouseDetails['+index+'].driverLicense', e.target.value)}
                        />
                        {/* <ErrorMessage name={`spouseDetails.${index}.driverLicense`} /> */}
                      </Label>
                    </div>

                    <div className="col-span-1 md:col-span-3">
                      <Label label="Residential address">
                        <Input name={`rentalPartners.${index}.residentAddress`} 
                          maxLength={50}
                          value={values.rentalPartners[index].residentAddress}
                          onChange={(e) => setFieldValue('rentalPartners['+index+'].residentAddress', e.target.value)}
                        />
                        {/* <ErrorMessage name={`spouseDetails.${index}.residentAddress`} /> */}
                      </Label>
                    </div>

                    <div className="col-span-1 md:col-span-3">
                      <h3 className="font-medium">Partner / Additional occupant employment</h3>
                    </div>

                    <div className="col-span-1 md:col-span-2">
                      <Label label="Employer name">
                        <Input name={`rentalPartners.${index}.employerName`} 
                          maxLength={50}
                          value={values.rentalPartners[index].employerName}
                          onChange={(e) => setFieldValue('rentalPartners['+index+'].employerName', e.target.value)}
                        />
                        {/* <ErrorMessage name={`spouseDetails.${index}.employerName`} /> */}
                      </Label>
                    </div>

                    <div>
                      <Label label={'Date of employment'}>
                        <CustomDatePicker
                          name={`rentalPartners.${index}.employmentDate`}
                          value={values.rentalPartners[index].employmentDate}
                          onChange={(date) => handleCalendarChange(`rentalPartners.${index}.employmentDate`, date)}
                        />
                        {/* <ErrorMessage name={`spouseDetails.${index}.employmentDate`} /> */}
                      </Label>
                    </div>

                    <div className="col-span-1 md:col-span-2">
                      <Label label="Employer address">
                        <Input name={`rentalPartners.${index}.employerAddress`} 
                          maxLength={50}
                          value={values.rentalPartners[index].employerAddress}
                          onChange={(e) => setFieldValue('rentalPartners['+index+'].employerAddress', e.target.value)}
                        />
                        {/* <ErrorMessage name={`spouseDetails.${index}.employerAddress`} /> */}
                      </Label>
                    </div>

                    <div>
                      <Label label="Monthly income">
                        <Input name={`rentalPartners.${index}.monthlyIncome`} 
                          type="number"
                          min={0}
                          placeholder="$ amount"
                          value={values.rentalPartners[index].monthlyIncome}
                          onChange={(e) => setFieldValue('rentalPartners['+index+'].monthlyIncome', e.target.value)}
                        />
                        {/* <ErrorMessage name={`spouseDetails.${index}.monthlyIncome`} /> */}
                      </Label>
                    </div>

                  </div>                  
                )
              })}    

              <div>
                <button title="Add new entry..." onClick={onAddSpouseDetail} >
                  <AddOutlined color="primary" fontSize="medium" />
                </button>
              </div>
              
              {/* BEGIN OF QUESTIONS / ANSWERS SECTION */}

              <div className="bg-sky-50 rounded-lg grid grid-cols-none md:grid-cols-6 gap-2 mt-3 p-3">
                <div className="col-span-4 font-medium">Have any of the occupants listed above ever been:</div>
                <div>Yes</div>
                <div>No</div>
                
                <div className="col-span-4 italic pl-2">Convicted of a felony?</div>
                <div>
                  <CheckBox 
                      disabled={values.convictions.felonyConviction === 'no'}
                      value={values.convictions.felonyConviction !== null && values.convictions.felonyConviction === 'yes' ? 'true' : 'false'}
                      onChange={(e) => onPartnerConvictionOptionChanged('felonyConviction', e.currentTarget.checked ? 'yes' : '')}                     
                  />
                </div>
                <div>
                  <CheckBox                     
                       disabled={values.convictions.felonyConviction === 'yes'}
                       value={values.convictions.felonyConviction !== null && values.convictions.felonyConviction === 'no' ? 'true' : 'false'}
                       onChange={(e) => onPartnerConvictionOptionChanged('felonyConviction', e.currentTarget.checked ? 'no' : '')}    
                  />
                </div>

                <div className="col-span-4 italic pl-2"><span>Received deferred adjudication for a felony?</span></div>
                <div>
                  <CheckBox
                      disabled={values.convictions.felonyDeferredAdjudication === 'no'}
                      value={values.convictions.felonyDeferredAdjudication !== null && values.convictions.felonyDeferredAdjudication === 'yes' ? 'true' : 'false'}
                      onChange={(e) => onPartnerConvictionOptionChanged('felonyDeferredAdjudication', e.currentTarget.checked ? 'yes' : '')}
                  />
                </div>
                <div>
                  <CheckBox
                    disabled={values.convictions.felonyDeferredAdjudication === 'yes'}
                    value={values.convictions.felonyDeferredAdjudication !== null && values.convictions.felonyDeferredAdjudication === 'no' ? 'true' : 'false'}
                    onChange={(e) => onPartnerConvictionOptionChanged('felonyDeferredAdjudication', e.currentTarget.checked ? 'no' : '')}
                  />
                </div>

                <div className="col-span-4 italic pl-2"><span>Been evicted, broken a lease or declared bankruptcy?</span></div>
                <div>
                    <CheckBox  
                      disabled={values.convictions.bankruptcyDeclaration === 'no'}
                      value={values.convictions.bankruptcyDeclaration !== null && values.convictions.bankruptcyDeclaration === 'yes' ? 'true' : 'false'}
                      onChange={(e) => onPartnerConvictionOptionChanged('bankruptcyDeclaration', e.currentTarget.checked ? 'yes' : '')}
                    />
                </div>
                <div>
                  <CheckBox  
                      disabled={values.convictions.bankruptcyDeclaration === 'yes'}
                      value={values.convictions.bankruptcyDeclaration !== null && values.convictions.bankruptcyDeclaration === 'no' ? 'true' : 'false'}
                      onChange={(e) => onPartnerConvictionOptionChanged('felonyDeferredAdjudication', e.currentTarget.checked ? 'no' : '')}
                    />
                </div>                
              </div>

              {/* END  OF  QUESTIONS / ANSWERS SECTION */}   

            </div>
          </div>

          {/* END OF PARTNER / ADDITIONAL OCCUPANT DETAILS */}          

          <div className='employment-form'>
            <FormDescription {...personalEmploymentDescription} />

            <div className='flex flex-col gap-[18px] pt-4'>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                <div className='w-full'>
                  <Label label={'Job title'}>
                    <Input {...getCommonInputProps('jobTitle', 'Job title')} disabled={values.retired} />
                    {showError('jobTitle') && <p className='text-xs text-error py-1'>{errors.jobTitle}</p>}
                  </Label>
                </div>
                <div className='w-full'>
                  <Label label={'Employment type'}>
                    <Select
                      {...getSelectProps('employmentType', employmentTypeOptions, 'Employment type')}
                      disabled={values.retired}
                    />
                  </Label>
                </div>
              </div>

              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                <div className='w-full'>
                  <Label label={'Company name'}>
                    <Input {...getCommonInputProps('companyName', 'Company name')} disabled={values.retired} />
                  </Label>
                </div>

                <div className='w-full'>
                  <Label label={'Length of employment'}>
                    <div className='flex gap-4'>
                      <div className='w-1/2'>
                        <Input
                          {...getCommonInputProps('employmentYears', 'Years')}
                          maxLength={2}
                          disabled={values.retired}
                        />
                        {showError('employmentYears') && (
                          <p className='text-xs text-error py-1'>{errors.employmentYears}</p>
                        )}
                      </div>
                      <div className='w-1/2'>
                        <Input
                          {...getCommonInputProps('employmentMonths', 'Months')}
                          maxLength={2}
                          disabled={values.retired}
                        />
                        {showError('employmentMonths') && (
                          <p className='text-xs text-error py-1'>{errors.employmentMonths}</p>
                        )}
                      </div>
                    </div>
                  </Label>
                </div>
              </div>

              <Label label={'Company address'}>
                <div className='place-autocomplete'>
                  <RenterPersonalDetailsFormAddressField
                    value={values.companyAddressField}
                    disabled={values.retired}
                    setValue={(value) => {
                      setFieldValue('companyAddressField', value.address);
                      if (!isNotEmptyString(value.address)) {
                        setFieldValue('companyAddress', '');
                        setFieldValue('companyCity', '');
                        setFieldValue('companyState', '');
                        setFieldValue('companyZip', '');
                      }
                    }}
                    onSelectAddress={(value) => {
                      setFieldValue('companyAddress', `${value.streetName} ${value.streetNumber}`);
                      setFieldValue('companyCity', `${value.city}`);
                      setFieldValue('companyState', `${value.state}`);
                      setFieldValue('companyZip', `${value.zip}`);
                      setFieldValue(
                        'companyAddressField',
                        formatAddress({
                          address: `${value.streetName}${value.streetNumber ? ' ' + value.streetNumber : ''}`,
                          city: value.city,
                          state: value.state,
                          zip: value.zip,
                        }),
                      );
                    }}
                    fieldName={'companyAddressField'}
                    invalid={Boolean(errors.companyAddressField && touched.companyAddressField)}
                  />
                  {Boolean(errors.companyAddressField && touched.companyAddressField) && (
                    <div className='text-red-500 text-sm text-red font-medium mt-2'>{errors.companyAddressField}</div>
                  )}
                </div>
              </Label>

              <Label label={'Household income'}>
                <Input {...getCommonInputProps('householdIncome', 'Household income')} disabled={values.retired} />
              </Label>

              <div>
                <div className='flex gap-9'>
                  <Checkbox
                    name={'retired'}
                    label={'Retired'}
                    value={values.retired}
                    checked={values.retired}
                    disabled={values.selfEmployed}
                    onChange={() => {
                      setFieldValue('retired', !values.retired).then(() => { });
                    }}
                  />
                  <Checkbox
                    name={'selfEmployed'}
                    label={'Self-Employed'}
                    value={values.selfEmployed}
                    checked={values.selfEmployed}
                    disabled={values.retired}
                    onChange={() => setFieldValue('selfEmployed', !values.selfEmployed)}
                  />
                </div>
                {showError('retired') && <p className='text-xs text-error py-1'>{errors.retired}</p>}
              </div>
            </div>
          </div>

            {/* BEGIN CREDIT OBLIGATIONS */}

            <div className="credit-obligation">
              <FormDescription {...creditObligationDescription} />         
                <div className="flex flex-col gap-[18px] pt-2">
                  <div className="grid grid-cols-none md:grid-cols-2 gap-x-2">
                    
                    <Label label={'Type'} />
                    <Label label={'Amount'} />
                  
                    {values.creditObligations && values.creditObligations.map((creditObligation, index) => {
                      return (
                        <div key={index} className="col-span-none md:col-span-2 mb-2">
                          <div className="grid grid-cols-none md:grid-cols-12 gap-x-2"> 
                            <div className="col-span-none md:col-span-5">
                                <ErrorValidationMessage touched={touched.creditObligations} message={errors.creditObligations}>
                                  <Select
                                    name={'obligation-type-' + index}
                                    options={creditObligationTypeOptions}
                                    value={values.creditObligations[index].obligationType}
                                    onChange={(value) => {
                                      values.creditObligations[index].obligationType = value;
                                    }}
                                    invalid={Boolean(errors.creditObligations && touched.creditObligations)}
                                    placeholder='Please, select type'
                                  />
                                </ErrorValidationMessage>
                            </div>
                            <div className="col-span-none md:col-span-5">
                                <ErrorValidationMessage touched={touched.creditObligations} message={errors.creditObligations}>
                                  <Input
                                    name={'obligations-amount-' + index}
                                    value={values.creditObligations[index].amount}
                                    maxLength={12}
                                    disabled={values.retired} 
                                    onChange={(e) => stringAsFloatNumber(e, 'creditObligations['+index+'].amount', setFieldValue) }/>
                                </ErrorValidationMessage>
                            </div>
                            <div className="col-span-none md:col-span-2 my-[5px]">
                              <button type="button" title="Remove"
                                className={"bg-slate-100 hover:bg-rose-200 w-[32px] h-[32px] rounded"}
                                onClick={(e) => onRemoveCreditObligation(creditObligation, index)}
                              >
                                <DeleteOutlinedIcon fontSize="small" sx={{ color: 'gray' }}/>
                              </button>                    
                            </div> 
                          </div>
                        </div>                    
                        );
                      })}
                  </div>
                  <div>
                    <button title="Add new entry..." onClick={onAddNewCreditObligation}>
                      <AddOutlined color="primary" fontSize="medium" />
                    </button>   
                  </div> 
              </div>   
            </div>

          {/* END CREDIT OBLIGATION */}

            <div className='education-form'>
              <FormDescription {...personalEducationDescription} />

              <div className='flex flex-col gap-[18px] pt-4'>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                  <div className='w-full'>
                    <Label label={'Institution name'}>
                      <Input {...getCommonInputProps('institutionName', 'Institution name')} />
                    </Label>
                  </div>

                  <div className='w-full'>
                    <Label label={'Highest degree earned'}>
                      <Select {...getSelectProps('educationEarned', educationEarnedOptions, 'Highest degree earned')} />
                    </Label>
                  </div>
                </div>
                <Label label={'Education address'}>
                  <div className='place-autocomplete'>
                    <RenterPersonalDetailsFormAddressField
                      value={values.educationAddressField}
                      setValue={(value) => {
                        setFieldValue('educationAddressField', value.address);
                        if (!isNotEmptyString(value.address)) {
                          setFieldValue('educationAddress', '');
                          setFieldValue('educationCity', '');
                          setFieldValue('educationState', '');
                          setFieldValue('educationZip', '');
                        }
                      }}
                      onSelectAddress={(value) => {
                        setFieldValue('educationAddress', `${value.streetName} ${value.streetNumber}`);
                        setFieldValue('educationCity', `${value.city}`);
                        setFieldValue('educationState', `${value.state}`);
                        setFieldValue('educationZip', `${value.zip}`);
                        setFieldValue(
                          'educationAddressField',
                          formatAddress({
                            address: `${value.streetName}${value.streetNumber ? ' ' + value.streetNumber : ''}`,
                            city: value.city,
                            state: value.state,
                            zip: value.zip,
                          }),
                        );
                      }}
                      fieldName={'educationAddressField'}
                      invalid={Boolean(errors.educationAddressField && touched.educationAddressField)}
                    />
                    {Boolean(errors.educationAddressField && touched.educationAddressField) && (
                      <div className='text-red-500 text-sm text-red font-medium mt-2'>
                        {errors.educationAddressField}
                      </div>
                    )}
                  </div>
                </Label>
              </div>
            </div>

            <div className='pets-form'>
              <FormDescription {...petsDescription} />

              <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 pt-4'>
                <div className='w-full'>
                  <Label label={'Pets'}>
                    <ErrorValidationMessage touched={touched.petsCount} message={errors.petsCount}>
                      <Input
                        name={'petsCount'}
                        value={values.petsCount}
                        onChange={(e) => stringAsIntegerNumber(e, `petsCount`, setFieldValue)}
                        invalid={Boolean(errors.petsCount && touched.petsCount)}
                        placeholder='0'
                      />
                    </ErrorValidationMessage>
                  </Label>
                </div>

                <div className='w-full'>
                  <Label label={'Type'}>
                    <ErrorValidationMessage touched={touched.petsTypes} message={errors.petsTypes}>
                      <MultiSelect
                        name={'petsTypes'}
                        options={petTypeOptions}
                        value={values.petsTypes}
                        onChange={(value) => {
                          setFieldValue('petsTypes', value)
                        }}
                        invalid={Boolean(errors.petsTypes && touched.petsTypes)}
                        placeholder='dog, cat'
                      />
                    </ErrorValidationMessage>
                  </Label>
                </div>

                <div className='w-full'>
                  <Label label={'Weight (largest pet)'}>
                    <ErrorValidationMessage touched={touched.petsWeight} message={errors.petsWeight}>
                      <Input
                        name={'petsWeight'}
                        value={values.petsWeight}
                        onChange={(e) => stringAsFloatNumber(e, `petsWeight`, setFieldValue)}
                        invalid={Boolean(errors.petsWeight && touched.petsWeight)}
                        placeholder='22lbs'
                      />
                    </ErrorValidationMessage>
                  </Label>
                </div>
              </div>
            </div>

            <div className="consent-acknowledgement">
              {/*<FormDescription {...consentAcknowledgementDescription} /> */}
                <p className="bg-sky-50 rounded-[18px] text-nowrap p-3">
                  The above listed applicant declares that all statements made in this application are true and complete. Applicant hereby
                  authorizes the Axxelist to verify all of the information in this application and obtain credit reports on the above listed 
                  applicant and/or applicants. If applicant(s) provided any false information Axxelist/Landlord is entitled to reject the 
                  application and retain all application fees as liquidated damages for Axxelist’s/Landlord’s time and expenses in processing 
                  this application. Applicant shall pay nonrefundable pre-application fee to process the application. 
                </p>
            </div>
            <div>
                <Button label={'I confirm'} theme={'primary'}
                  onClick={handleOpenConsentAcknowledgment}
                />
            </div>

            <div className='button-wrapper flex justify-end gap-3 items-center'>
              <Progress
                strokeColor={colors.primary}
                percent={totalDataUoadingPercent}
                className={`m-0 ${isUpoadingProgressVisible ? '' : 'opacity-0'} transition ease-in-out delay-150`}
              />
              <Button
                label={'Save'}
                theme={'primary'}
                onClick={() => {
                  // if (!isValid) {
                  //   changeInvalidDocumentTab(errors);
                  // }
                  submitForm();
                }}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export { RenterPersonalDetailsForm };

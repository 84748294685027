import { SelectOption } from 'common/types/types';
import UsStates from 'common/utils/us-states';

export const profileStepperList = [
  {
    id: 'personal',
    title: 'Personal',
  },
  {
    id: 'payment-details',
    title: 'Payment',
  },
  {
    id: 'verification',
    title: 'Verification',
  }
];

export const statesSelectOptions: SelectOption[] = UsStates.map((item) => {
  return {
    label: item.name,
    value: item.abbreviation,
  };
});

export const personalDetailsFormDescription = {
  title: 'Personal Details',
  subTitle: 'Please start filling out your application by filling in your personal information below.',
};

export const documentFormDescription = {
  title: 'Documents *',
  subTitle: 'Please upload at least 1 of the following documents',
};

export const personalAddressFormDescription = {
  title: 'Current Residential Address *',
  subTitle: 'To avoid service restrictions, ensure you fill out your address details.',
};

export const personalEmploymentDescription = {
  title: 'Employment',
  subTitle: 'Please start filling out your application by filling in your personal information below.',
};

export const personalEducationDescription = {
  title: 'Education',
  subTitle: 'Please start filling out your application by filling in your personal information below.',
};

export const petsDescription = {
  title: 'Pets',
  subTitle: 'Please start filling out your application by filling in your personal information below.',
};

export const previousResidentsDescription = {
  title: 'Rental history',
  subTitle: 'Please provide an information of your past rental experience below.',
};

export const spouseDetailDescription = {
  title: 'Spouse / Additional occupant details',
  subTitle: 'Please share information about the people you live with below',
};

export const creditObligationDescription = {
  title: 'Credit obligations',
  subTitle: 'Please provide an information of credit obligations below.',
};

export const consentAcknowledgementDescription = {
  title: 'Consent & Acknowledgement'
};


export const employmentTypeOptions: SelectOption[] = [
  { label: 'Full-time', value: 'fullTime' },
  { label: 'Part-time', value: 'partTime' },
];

export const educationEarnedOptions: SelectOption[] = [
  { label: 'High School', value: 'highSchool' },
  { label: "College Associate's", value: 'collegeAssociates' },
  { label: "College Bachelor's", value: 'collegeBachelors' },
  { label: "Master's", value: 'masters' },
  { label: 'PHD/Doctorate', value: 'phdDoctorate' },
];

export const petTypeOptions: SelectOption[] = [{
  value: 'Cat',
  label: 'Cat'
}, {
  value: 'Dog',
  label: 'Dog'
}];

export const creditObligationTypeOptions: SelectOption[] = [{
    value: 'rent',
    label: 'Rent'
  }, {
    value: 'mortgage',
    label: 'Mortgage'
  },
  {
    value: 'credit-card',
    label: 'Credit card'
  },
  {
    value: 'car-loans-or-lease',
    label: 'Car loans / lease'
  },
  {
    value: 'personal-loans',
    label: 'Personal loans'
  },
  {
    value: 'student-loans',
    label: 'Student loans'
  },
  {
    value: 'bank-loans',
    label: 'Bank loans'
  }];

import React from 'react';
import { Avatar, Icon } from 'legacy-components/componets';
import { joinValues } from 'helpers/helpers';
import { getDayjs } from 'helpers/date';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { isNotEmptyString } from 'common/utils/check-empty-string';

export interface IApplicationPersonalInfoProps {
  firstName: string;
  lastName: string;
  img: string;
  state: string;
  city: string;
  ssn: string;
  dateOfBirth: Date;
  years: number;
  isVerified: boolean;
  // phoneNumber: string;
  // email: string;
}

const ApplicationPersonalInfo = ({
  firstName,
  lastName,
  img,
  state,
  city,
  ssn,
  dateOfBirth,
  years,
  isVerified,
}: // email,
// phoneNumber,
IApplicationPersonalInfoProps) => {
  const [showSSN, setShowSSN] = React.useState<boolean>(false);

  const visibleSSN = ssn.slice(0, 3);
  const hiddenSSN = ssn.slice(3, ssn.length);
  const { isTablet } = useWindowSize();
  return (
    <div className='personal-info w-full border-[1.5px] border-primary flex flex-col gap-6 rounded-lg'>
      <div className={`py-4 px-10 grid grid-cols-1 md:grid-cols-2`}>
        <div className='flex flex-col gap-3 items-center mb-2'>
          {!img && (firstName || lastName) ? (
            <div className='w-[100px] h-[100px] rounded-full bg-slate-400 flex justify-center items-center'>
              <div className='text-white text-4xl'>{firstName[0].toUpperCase()}</div>
            </div>
          ) : (
            <Avatar src={img} width={100} height={100} />
          )}
          <div className='flex max-w-[100%]'>
            <div className={'text-xl font-bold text-start truncate'}>{joinValues([firstName, lastName], ' ')}</div>
          </div>
          {isVerified && (
            <div className='flex items-center gap-2 text-[#27AE60]'>
              <Icon name={'circle-checked'} height={16} width={16} />
              <span className='text-xs font-bold uppercase'>identity verified</span>
            </div>
          )}
        </div>
        <div className={`grow flex flex-col gap-6 ${!isTablet && 'pl-8 py-4 pr-4'}`}>
          {isNotEmptyString(ssn) && (
            <div className='ssn flex flex-col md:flex-row items-center justify-between py-4 px-3.5 bg-secondary rounded-lg'>
              <div className='flex items-center'>
                <input
                  readOnly
                  value={visibleSSN}
                  disabled={true}
                  className={'bg-secondary text-sm font-medium'}
                  style={{ width: `${visibleSSN.length}ch` }}
                />
                <input
                  readOnly
                  value={hiddenSSN}
                  disabled={true}
                  className={'bg-secondary text-sm font-medium'}
                  type={showSSN ? 'text' : 'password'}
                  style={{ width: `${hiddenSSN.length + 2}ch` }}
                />
              </div>
              <span
                className='cursor-pointer text-sm text-primary font-bold'
                onClick={() => setShowSSN((prevState) => !prevState)}
              >
                {showSSN ? 'Hide SSN' : 'Show SSN'}
              </span>
            </div>
          )}
          <div className='flex flex-col'>
            <div className='flex items-center justify-between py-4 border-t border-secondary text-base font-medium'>
              <div className='text-trueGray'>Date of Birth</div>
              <div className='text-black'>{getDayjs(dateOfBirth).format('DD-MM-YYYY')}</div>
            </div>
            <div className='flex items-center justify-between py-4 border-t border-secondary text-base font-medium'>
              <div className='text-trueGray'>Age</div>
              <div className='text-black'>{`${years} years old`}</div>
            </div>
            {/* <div className='flex items-center justify-between py-4 border-t border-secondary text-base font-medium'>
              <div className='text-trueGray'>Email</div>
              <div className='text-black'>{email}</div>
            </div>
            <div className='flex items-center justify-between py-4 border-t border-secondary text-base font-medium'>
              <div className='text-trueGray'>Phone Number</div>
              <div className='text-black'>{phoneNumber}</div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className='py-8 px-12 bg-secondary'>
        <Button
          label={'Email applicant'}
          theme={'primary'}
          icon={{
            name: 'email',
            color: 'white',
            height: 20,
            width: 20,
          }}
          stylesConfig={{ width: '100%' }}
          onClick={(e) => {
            window.location.href = `mailto:${email}`;
            e.preventDefault();
          }}
        />
      </div> */}
    </div>
  );
};

export { ApplicationPersonalInfo };

import { ssnLength } from 'common/constants/userConstants';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import dayjs from 'dayjs';
import { isDateValid } from 'helpers/date';
import { emailValidation } from 'validations/email.validation';
import * as Yup from 'yup';

const supportedPropertyImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/heic', 'image/heif'];

const isImgFile = (file: File) => supportedPropertyImageTypes.includes(file?.type);

const alphabetValidation = Yup.string()
  .matches(/^[A-Za-z\s]*$/, 'Field can not be numeric or contain special characters')
  .max(255);

const onlyJPGFileAllowedMessage = 'Only JPG or PNG file is allowed';
// const isJPGFile = (file: File) => file.type === 'image/jpeg';

const builtMaximumAllowedFileSizeMessage = (fileSize: number) =>
  `${kbToMb(fileSize)} MB is the maximum allowed size for a file`;

const mbToKb = (mb: number) => mb * 1024 * 1024;
const kbToMb = (mb: number) => mb / 1024 / 1024;

const yupNullableJpg75MbFileObjectSchema = Yup.object({
  file: Yup.mixed()
    .test({
      message: builtMaximumAllowedFileSizeMessage(mbToKb(75)),
      test: (file) => {
        if (!file) return true;
        return (file as File).size <= mbToKb(75);
      },
    })
    .test({
      message: onlyJPGFileAllowedMessage,
      test: (file) => {
        if (!file) return true;
        return isImgFile(file as File);
      },
    }),
});

export const renterProfileValidation = Yup.object({
  firstName: alphabetValidation.required('First Name is required'),
  lastName: alphabetValidation.required('Last Name is required'),
  email: emailValidation,
  dateOfBirth: Yup.date()
    .min(new Date(new Date().getFullYear() - 100, 0, 1), `Date can't be earlier than 100 years ago`)
    .nullable()
    .transform((curr, orig) => {
      return orig === '' && orig === 'Invalid Date' ? null : curr;
    })
    .typeError('Invalid date')
    .test({
      message: 'Date of birth can not be Less than 18 years from current date',
      test: (value) => dayjs(value).isSameOrBefore(dayjs().subtract(18, 'year'), 'day'),
    }),
  phoneNumber: Yup.string().required('Phone number is required'),
  // driversLicenseNumber: Yup.string(),
  // driversLicenseState: Yup.string(),
  // driversLicenseIssueDate: Yup.date()
  //   .nullable()
  //   .transform((curr, orig) => {
  //     return orig === '' && orig === 'Invalid Date' ? null : curr;
  //   })
  //   .typeError('Invalid date')
  //   .max(dayjs(), 'Drivers license issue date date must be less than current date')
  //   .when('dateOfBirth', ([dateOfBirth], schema) => {
  //     if (dateOfBirth && isDateValid(dateOfBirth)) {
  //       return schema.min(dateOfBirth, 'Drivers license issue date must be after date of birth');
  //     }
  //     return schema;
  //   })
  //   .max(
  //     new Date(new Date().getFullYear() + 50, 0, 1),
  //     'Drivers license issue date cannot be more than 50 years in the future',
  //   ),
  // driversLicenseExpirationDate: Yup.date()
  //   .nullable()
  //   .transform((curr, orig) => {
  //     return orig === '' && orig === 'Invalid Date' ? null : curr;
  //   })
  //   .typeError('Invalid date')
  //   .test({
  //     message: 'Drivers license expiration date can not be past date',
  //     test: (value) => {
  //       return dayjs(value).isSameOrAfter(dayjs(), 'day');
  //     },
  //   })
  //   .when('driversLicenseIssueDate', ([driversLicenseIssueDate], schema) => {
  //     if (driversLicenseIssueDate && isDateValid(driversLicenseIssueDate)) {
  //       return schema.min(
  //         dayjs(driversLicenseIssueDate).add(1, 'day'),
  //         'Drivers license expiration date must be after Drivers license issue date',
  //       );
  //     }
  //     return schema;
  //   })
  //   .max(
  //     new Date(new Date().getFullYear() + 50, 0, 1),
  //     'Drivers license expiration date cannot be more than 50 years in the future',
  //   ),
  // driverLicense: yupNullableJpg75MbFileObjectSchema,
  // passport: yupNullableJpg75MbFileObjectSchema,
  // isSSNNumber: Yup.bool(),
  socialSecurityNumber: Yup.string().length(ssnLength, `Social Security Number must be exactly ${ssnLength} characters`),
  gra: Yup.string(),
  // passportNumber: Yup.string(),
  // passportCountry: Yup.string(),
  // passportIssueDate: Yup.date()
  //   .nullable()
  //   .transform((curr, orig) => {
  //     return orig === '' && orig === 'Invalid Date' ? null : curr;
  //   })
  //   .typeError('Invalid date')
  //   .max(dayjs(), 'Passport issue date date must be less than current date')
  //   .when('dateOfBirth', ([dateOfBirth], schema) => {
  //     if (dateOfBirth && isDateValid(dateOfBirth)) {
  //       return schema.min(dateOfBirth, 'Passport Issue date must be after date of birth');
  //     }
  //     return schema;
  //   })
  //   .max(
  //     new Date(new Date().getFullYear() + 50, 0, 1),
  //     'Passport issue date cannot be more than 50 years in the future',
  //   ),
  // passportExpirationDate: Yup.date()
  //   .nullable()
  //   .transform((curr, orig) => {
  //     return orig === '' && orig === 'Invalid Date' ? null : curr;
  //   })
  //   .typeError('Invalid date')
  //   .test({
  //     message: 'Passport expiration date can not be past date',
  //     test: (value) => {
  //       return dayjs(value).isSameOrAfter(dayjs(), 'day');
  //     },
  //   })
  //   .when('passportIssueDate', ([passportIssueDate], schema) => {
  //     if (passportIssueDate && isDateValid(passportIssueDate)) {
  //       return schema.min(
  //         dayjs(passportIssueDate).add(1, 'day'),
  //         'Passport expiration date must be after Passport issue date',
  //       );
  //     }
  //     return schema;
  //   })
  //   .max(
  //     new Date(new Date().getFullYear() + 50, 0, 1),
  //     'Passport expiration date cannot be more than 50 years in the future',
  //   ),
  // socialSecurityNumber: Yup.string().when('isSSNNumber', {
  //   is: true,
  //   then: (schema) => schema.notRequired(),
  //   otherwise: (schema) => schema.length(9, 'Social Security Number must be exactly 9 characters'),
  // }),
  address: Yup.string(),
  city: alphabetValidation,
  state: Yup.string(),
  zip: Yup.string(),

  addressField: Yup.string().test(
    'can-be-submitted-address',
    'Please provide a valid address (Property Number/Street Name/City, State, Zip)',
    function (value) {
      if (isNotEmptyString(value || '')) {
        const address = this.resolve(Yup.ref('address')) as string;
        const city = this.resolve(Yup.ref('city')) as string;
        const state = this.resolve(Yup.ref('state')) as string;
        const zip = this.resolve(Yup.ref('zip')) as string;
        const nonEmptyStrings = [address, city, state, zip].filter(isNotEmptyString);

        if (nonEmptyStrings.length !== 4) {
          return false;
        }
      }
      return true;
    },
  ),
  periodYears: Yup.number().test(
    'periodYears-gteZero', 
    'The value should be >= 0', 
    (value) => value !== undefined && value >= 0    
  ),
  periodMonths: Yup.number().test(
    'periodMonths-gteZero', 
    'The value should be >= 0', 
    (value) => value !== undefined && value >= 0    
  ),
  companyAddress: Yup.string(),
  companyCity: alphabetValidation,
  companyState: Yup.string(),
  companyZip: Yup.string(),

  companyAddressField: Yup.string().test(
    'can-be-submitted-company-address',
    'Please provide a valid company address (Property Number/Street Name/City, State, Zip)',
    function (value) {
      if (isNotEmptyString(value || '')) {
        const address = this.resolve(Yup.ref('companyAddress')) as string;
        const city = this.resolve(Yup.ref('companyCity')) as string;
        const state = this.resolve(Yup.ref('companyState')) as string;
        const zip = this.resolve(Yup.ref('companyZip')) as string;
        const nonEmptyStrings = [address, city, state, zip].filter(isNotEmptyString);

        if (nonEmptyStrings.length !== 4) {
          return false;
        }
      }
      return true;
    },
  ),

  educationAddress: Yup.string(),
  educationCity: alphabetValidation,
  educationState: Yup.string(),
  educationZip: Yup.string(),

  educationAddressField: Yup.string().test(
    'can-be-submitted-educationAddressField',
    'Please provide a valid education address (Property Number/Street Name/City, State, Zip)',
    function (value) {
      if (isNotEmptyString(value || '')) {
        const address = this.resolve(Yup.ref('educationAddress')) as string;
        const city = this.resolve(Yup.ref('educationCity')) as string;
        const state = this.resolve(Yup.ref('educationState')) as string;
        const zip = this.resolve(Yup.ref('educationZip')) as string;
        const nonEmptyStrings = [address, city, state, zip].filter(isNotEmptyString);

        if (nonEmptyStrings.length !== 4) {
          return false;
        }
      }
      return true;
    },
  ),

  jobTitle: alphabetValidation,
  employmentType: Yup.string(),
  companyName: Yup.string(),
  employmentYears: Yup.string()
    .matches(/^[1-9][0-9]?$/, "Field should be numeric and don't start from 0")
    .max(2),
  employmentMonths: Yup.string()
    .matches(/^[1-9][0-9]?$/, "Field should be numeric and don't start from 0")
    .max(2),
  householdIncome: Yup.string(),
  retired: Yup.bool().when('selfEmployed', {
    is: true,
    then: (schema) => schema.isFalse('User cannot have two checked boxes at the same time'),
  }),
  selfEmployed: Yup.bool(),
  institutionName: Yup.string(),
  educationEarned: Yup.string(),
  rentalHistory: Yup.array()
      .of(Yup.object({
          address: Yup.string(),
          city: Yup.string(),
          state: Yup.string(),
          zip: Yup.string(),
          periodMonths: Yup.number(),
          periodYears: Yup.number(),
          ownerFullName: Yup.string(),
          ownerEmail: Yup.string(),
          ownerPhoneNumber: Yup.string()
      }))
});

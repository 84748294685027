import cn from 'clsx';
import { Icon } from 'legacy-components/componets';
import { InputProps } from 'common/types/types';
import { generateIconComponentsProps } from 'helpers/components';

import styles from './styles';

const Input = ({
  disabled,
  name,
  value,
  type = 'text',
  theme = 'default',
  placeholder,
  onChange,
  onClick,
  onIconClick,
  label,
  icon,
  invalid,
  inputRef,
  onBlur,
  onFocus,
  stylesConfig,
  maxLength,
  autoCompleteType = 'off',
  iconComponent,
  min,
  max
}: InputProps) => {
  const inputClassName = cn(
    styles[theme].input,
    invalid && styles[theme].error,
    disabled && 'bg-[#FAFAFA] text-black !cursor-not-allowed hover:border-neutral-200', // all inputs must have the same disabled style
    icon && 'pr-8', // TODO:  icon overlap text, maybe it makes sense to rewrite Input
  );

  const generatedIconProps = icon && Boolean(Object.keys(icon).length) && generateIconComponentsProps(icon);

  return (
    <div className={styles[theme].container}>
      {label && (
        <label htmlFor={name} className={styles[theme].label} style={stylesConfig?.labelStyle}>
          {label}
        </label>
      )}
      <div className='relative'>
        <input
          id={name}
          disabled={disabled}
          name={name}
          type={type}
          placeholder={placeholder}
          className={inputClassName}
          onChange={onChange}
          onClick={onClick}
          value={value}
          ref={inputRef}
          autoComplete={autoCompleteType}
          style={stylesConfig?.inputStyle}
          onBlur={onBlur ? () => onBlur(value) : () => {}}
          onFocus={onFocus}
          maxLength={maxLength}
          min={min}
          max={max}
        />
        {generatedIconProps && (
          <div
            className={`absolute inset-y-0 right-0 -z-1 flex items-center pr-3 ${onIconClick && 'cursor-pointer'} ${
              !onIconClick && 'pointer-events-none'
            }`}
            onClick={onIconClick}
          >
            <Icon {...generatedIconProps} width={20} height={20} />
          </div>
        )}
        {iconComponent && iconComponent}
      </div>
    </div>
  );
};

export { Input };

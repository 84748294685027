import { dateFormatMMMM_DD_YYYY_HH_MM_A } from 'helpers/date';
import { twMerge } from 'tailwind-merge';
import { NotificationDto } from 'common/types/services/api/notification/notifications-dto.type';
import DoubleCheckIcon from 'legacy-components/double-check-icon/double-check-icon';
import { Link } from 'react-router-dom';
import { NotificationItemCategoryDto } from 'common/enums/enums';
import { useReadNotification } from 'hooks/query';
import { PaginationDtoRequest } from 'common/types/services/api/common/pagination-dto.type';
import { isNotEmptyString } from 'common/utils/check-empty-string';

export type NotificationItemProps = {
  notification: NotificationDto;
  onClick: () => void;
  params: PaginationDtoRequest;
};

const NotificationItem = ({ notification, onClick, params }: NotificationItemProps) => {
  const { mutate: readNotification } = useReadNotification(params);

  const markAsRead = () => {
    readNotification(notification.id);
  };

  const handleClick = () => {
    onClick();
    if (!notification.isRead) {
      markAsRead();
    }
  };

  const notificationCategoryToTitleMap: Record<NotificationItemCategoryDto, string> = {
    [NotificationItemCategoryDto.LandlordOfferNew]: 'You have a new offer',
    [NotificationItemCategoryDto.LandlordOfferPaid]: 'Offer is paid',
    [NotificationItemCategoryDto.LandlordTourNew]: 'You have a new tour',
    [NotificationItemCategoryDto.LandlordTourFinised]: 'Renter has completed the tour. Please share feedback',
    [NotificationItemCategoryDto.RenterOfferAccepted]: 'Your offer is accepted',
    [NotificationItemCategoryDto.RenterOfferRejected]: 'Your offer is rejected',
    [NotificationItemCategoryDto.RenterOfferPaid]: 'Your offer is paid',
    [NotificationItemCategoryDto.RenterTourNew]: 'Your tour is scheduled',
    [NotificationItemCategoryDto.RenterTourExpired]: 'Your tour is expired',
    [NotificationItemCategoryDto.NewTourSccheduleConfirmedRenter]: 'Your tour booking was confirmed by Landlord',

    //TODO: ADD ADDITIONAL DATA ON BE SIDE
    [NotificationItemCategoryDto.LandlordTourEndFeedback]: 'You received a new feedback',
    [NotificationItemCategoryDto.RenterTourRescheduled]: 'The tour was rescheduled',
    [NotificationItemCategoryDto.LandlordTourRescheduled]: 'The tour was rescheduled',
    [NotificationItemCategoryDto.RenterEstateUnavaliable]: 'Property no longer available',
    [NotificationItemCategoryDto.RenterUnitOccupied]: 'Property No Longer Available for Rent',
    [NotificationItemCategoryDto.LandlordTrialWillEnd]: 'Reminder: Trial Ends in 3 Days',
    [NotificationItemCategoryDto.LandlordOfferUpdated]: 'Offer has been updated',
    [NotificationItemCategoryDto.RenterTourRejected]: 'Tour canceled by landlord',
    [NotificationItemCategoryDto.UserGetGiftCard]: 'Congratulation! You won a Gift Card to Starbucks. Please, check your phone for Starbucks Card number and Security Code',
    [NotificationItemCategoryDto.LandlordEstateRejectedByAdmin]: 'Your estate has been rejected'
  };

  const notificationCategoryToLinkMap: Record<NotificationItemCategoryDto, string> = {
    [NotificationItemCategoryDto.LandlordOfferNew]: `/i-landlord/profile/offers/${notification.data.entityId}`,
    [NotificationItemCategoryDto.LandlordOfferPaid]: `/i-landlord/profile/offers/${notification.data.entityId}`,
    [NotificationItemCategoryDto.LandlordTourNew]: `/i-landlord/profile/schedule`,
    [NotificationItemCategoryDto.LandlordTourFinised]: `/i-landlord/profile/schedule?modal=tourFeedback&tourId=${notification.data.entityId}`,
    [NotificationItemCategoryDto.RenterOfferAccepted]: '/i-rent/profile/offers',
    [NotificationItemCategoryDto.RenterOfferRejected]: '/i-rent/profile/offers',
    [NotificationItemCategoryDto.LandlordOfferUpdated]: `/i-landlord/profile/offers/${notification.data.entityId}`,
    [NotificationItemCategoryDto.RenterTourRejected]: '/i-rent/profile/tours/history',
    [NotificationItemCategoryDto.RenterTourExpired]: '/i-rent/profile/tours/history',
    [NotificationItemCategoryDto.RenterOfferPaid]: '',
    [NotificationItemCategoryDto.RenterTourNew]: '',
    [NotificationItemCategoryDto.UserGetGiftCard]: '',

    [NotificationItemCategoryDto.NewTourSccheduleConfirmedRenter]: '/i-rent/profile/tours/upcoming',    
    [NotificationItemCategoryDto.LandlordEstateRejectedByAdmin]: `/i-landlord/properties/details/${notification.data.entityId}`,

    //TODO: ADD REDIRECTS
    [NotificationItemCategoryDto.LandlordTourEndFeedback]: '',
    [NotificationItemCategoryDto.RenterTourRescheduled]: '',
    [NotificationItemCategoryDto.LandlordTourRescheduled]: '',
    [NotificationItemCategoryDto.RenterEstateUnavaliable]: '',
    [NotificationItemCategoryDto.RenterUnitOccupied]: '',
    [NotificationItemCategoryDto.LandlordTrialWillEnd]: '',
  };

  return (
    <Link
      to={notificationCategoryToLinkMap?.[notification.category] ?? ''}
      onClick={handleClick}
      className={twMerge(
        'block p-4 rounded-lg bg-secondary',
        notification.isRead && 'bg-lime-50 border border-indigo-50',
      )}
    >
      <div>
        <div className='text-base font-semibold mb-0.5'>{notificationCategoryToTitleMap[notification.category]}</div>
        {isNotEmptyString(notification.data.additional?.RejectReason || '') && (
          <div className='text-neutral-800 text-sm truncate'>
            <span className='font-semibold'>Reason: </span>
            <span>{notification.data.additional?.RejectReason}</span>
          </div>
        )}
        <div className='text-neutral-800 text-sm font-normal truncate'>{notification.data.additional.Location}</div>
        <div className='text-sm text-trueGray'>{dateFormatMMMM_DD_YYYY_HH_MM_A(notification.occurredOn)}</div>
        {notification.isRead ? (
          <div className='flex gap-2 mt-3'>
            <DoubleCheckIcon className={'w-5 h-5'} color={'#52C41A'} />
            <span className='text-[#52C41A] text-sm font-medium leading-[21px]'>Read</span>
          </div>
        ) : (
          <button
            className='flex gap-2 text-primary mt-3'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              markAsRead();
            }}
          >
            <DoubleCheckIcon className={'w-5 h-5'} />
            <span className='text-right text-sky-300 text-sm font-medium leading-[21px]'>Mark as read</span>
          </button>
        )}
      </div>
    </Link>
  );
};

export { NotificationItem };

const {
  REACT_APP_API_ORIGIN_URL,
  REACT_APP_STRIPE_PUBLIC_KEY,
  REACT_APP_ORIGIN_URL,
  REACT_APP_GOOGLE,
  REACT_APP_YBUG_ID,
  REACT_APP_HOTJAR_ID,
  REACT_APP_HOTJAR_SNIPPET_VERSION,
  REACT_APP_STRICT_AUTH,
  REACT_APP_PASSWORD,
  REACT_APP_RECAPTCHA,
  REACT_APP_CLIENT_URL,
  REACT_APP_BANNER,
  REACT_APP_VERSIONN,
  REACT_APP_PERSONA_ENVIRONMENT_ID,
  REACT_APP_PERSONA_TEMPLATE_ID,
} = process.env;

const ENV = {
  API_PATH: REACT_APP_API_ORIGIN_URL ?? '',
  APP_ORIGIN_PATH: REACT_APP_ORIGIN_URL ?? '',
  REPLENISH_PUBLIC_KEY: REACT_APP_STRIPE_PUBLIC_KEY ?? '',
  REACT_APP_GOOGLE: REACT_APP_GOOGLE ?? '',
  REACT_APP_YBUG_ID: REACT_APP_YBUG_ID ?? '',
  HOTJAR_ID: parseInt(REACT_APP_HOTJAR_ID ?? ''),
  HOTJAR_SNIPPET_VERSION: parseInt(REACT_APP_HOTJAR_SNIPPET_VERSION ?? '6'),
  REACT_APP_STRICT_AUTH: REACT_APP_STRICT_AUTH === 'true',
  REACT_APP_PASSWORD: REACT_APP_PASSWORD ?? '',
  REACT_APP_RECAPTCHA: REACT_APP_RECAPTCHA ?? '',
  REACT_APP_CLIENT_URL: REACT_APP_CLIENT_URL ?? '',  
  REACT_APP_BANNER: REACT_APP_BANNER === 'true',
  REACT_APP_VERSIONN: REACT_APP_VERSIONN ?? '',
  REACT_APP_PERSONA_ENVIRONMENT_ID: REACT_APP_PERSONA_ENVIRONMENT_ID ?? '',
  REACT_APP_PERSONA_TEMPLATE_ID: REACT_APP_PERSONA_TEMPLATE_ID ?? ''
} as const;

export { ENV };
